import React from "react";

import {
  Button,
  Col,
  Form,
  FormCheckbox, FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import PiecesSection from "../../views/form-sections/PiecesSection";

export default function AnonymousForm(props){
  const [pieces, setPieces] = React.useState([
    {
      description: '',
      reference: ''
    }
  ]);

  const addPiece = () => {
    const newPieza = {
      description: '',
      reference: ''
    }
    setPieces([
      ...pieces,
      newPieza
    ])
  }

  return (
    <Row className="">
      <Col sm="12" className="d-flex mb-2 mb-sm-0">
        <h1>Pedido Piezas</h1>

      </Col>
      <Col sm={12}>
        <h3>Datos del vehiculo</h3>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col sm="12" md="12" className="mb-3">
                      <strong className="text-muted d-block mb-2">Tipo vehiculo</strong>
                      <fieldset>
                        <FormCheckbox>Motocicleta</FormCheckbox>
                        <FormCheckbox defaultChecked>Turismo</FormCheckbox>
                        <FormCheckbox>Vehiculo industrial</FormCheckbox>
                      </fieldset>
                    </Col>
                    <Col sm={12}>
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="brand">Marca</label>
                          <FormInput
                            id="brand"
                            type="string"
                            placeholder="Marca"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="model">Modelo</label>
                          <FormInput
                            id="model"
                            type="string"
                            placeholder="Modelo"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="variant">Variante</label>
                          <FormInput
                            id="variant"
                            type="string"
                            placeholder="Variante"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12}>
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="chassis">Chasis</label>
                          <FormInput
                            id="chassis"
                            type="string"
                            placeholder="Chasis"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="year">Año fabricación</label>
                          <FormInput
                            id="year"
                            type="string"
                            placeholder="Año de fabricación"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="doors">Puertas</label>
                          <FormInput
                            id="doors"
                            type="string"
                            placeholder="Puertas"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12}>
                      <Row>
                        <Col sm="12" md="6" className="mb-3">
                          <strong className="text-muted d-block mb-2">Motor</strong>
                          <fieldset>
                            <FormCheckbox>Gasolina</FormCheckbox>
                            <FormCheckbox defaultChecked>Diesel</FormCheckbox>
                          </fieldset>
                        </Col>
                        <Col sm="12" md="6" className="mb-3">
                          <strong className="text-muted d-block mb-2">Aire Acondicionado</strong>
                          <fieldset>
                            <FormCheckbox>Si</FormCheckbox>
                            <FormCheckbox defaultChecked>No</FormCheckbox>
                          </fieldset>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Col>
      <Col sm={12}>
        <h3>Datos del taller</h3>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="email">Email</label>
                      <FormInput
                        id="email"
                        type="email"
                        placeholder="Email"
                      />
                    </Col>
                    <Col md="6">
                      <label htmlFor="password">Password</label>
                      <FormInput
                        id="password"
                        type="password"
                        placeholder="Password"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" className="form-group">
                      <label htmlFor="name">Nombre</label>
                      <FormInput id="name" placeholder="Pedro" />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="nif">NIF</label>
                      <FormInput id="nif" placeholder="447933788J" />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="phone">Teléfono</label>
                      <FormInput id="phone" placeholder="666666666" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" className="form-group">
                      <label htmlFor="address">Dirección</label>
                      <FormInput id="address" placeholder="1234 Main St" />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="province">Provincia</label>
                      <FormInput id="province" placeholder="Valencia" />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="poblacion">Población</label>
                      <FormInput id="poblacion" placeholder="Saunto" />
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Col>
      <Col sm={12}>
        <PiecesSection
          pieces={pieces}
          addPiece={addPiece}
        />
      </Col>
      <Col md="12" className="form-group">
        <FormCheckbox>
          {/* eslint-disable-next-line */}I agree with your{" "}
          <a href="#">Privacy Policy</a>.
        </FormCheckbox>
        <Button theme="primary" className="mb-2 mr-1">
          Submit
        </Button>
      </Col>
    </Row>
  )
}



import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {connect} from 'react-redux';

import moment from 'moment';

const DayScaleCell = ({ startDate, endDate, today, trackingsList, ...rest }) => {

  let totalHours = 0;

  if(trackingsList != undefined){
    //calcular horas para este dia
    //elegir los eventos que corresponden a este dia
    let dayList = trackingsList["hydra:member"].filter(item => {

      let itemDate = new Date(item.startDate);
      let itemFormatedDate = Intl.DateTimeFormat("en-US", { year:"numeric", month:"numeric", day: "numeric" }).format(itemDate);
      let currentFormatedDate = Intl.DateTimeFormat("en-US", { year:"numeric", month:"numeric", day: "numeric" }).format(startDate);

      if(itemFormatedDate === currentFormatedDate){
        return item;
      }else{
        return null;
      }
    });
    //calcualr
    if(dayList.length > 0){
      dayList.map( item => {
        let start = moment(item.startDate);
        let finish = item.endDate ? moment(item.endDate) : moment(new Date());

        let total = finish.diff(start, 'hours', true)

        totalHours = parseFloat(totalHours + total).toFixed(2);
        return item;
      })
    }

  }
  return (
    <TableCell>
      <p style={{color: "rgba(0, 0, 0, 0.54)", fontSize:"0.75rem", lineHeight:"1.17"}}>
        {Intl.DateTimeFormat("en-US", { weekday: "short" }).format(startDate)}
      </p>
      <div style={{fontSize: "1.8rem", lineHeight:"1.2", color: "rgba(0, 0, 0, 0.54)"}}>
        {Intl.DateTimeFormat("en-US", { day: "numeric" }).format(startDate)}
      </div>
      <p>{totalHours}/8</p>
    </TableCell>
  );
}

const mapStateToProps = state => {

  const trackingsList = state.tracking.list.retrieved;

  return {trackingsList}
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayScaleCell);

import React, { Component } from 'react';
import Form from './Form'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, reset } from '../../actions/auth/login';
import logo from '../../images/brand/logo_black.png';
import {Link} from "react-router-dom";
import {Col, Row} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PhotographerInfo} from "../../views/StaticWidgets/PhotographerInfo";

class Login extends Component {

  static propTypes = {
      error: PropTypes.string,
      loading: PropTypes.bool.isRequired,
      token: PropTypes.string,
      login: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired
  };

  componentDidMount(){

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentWillUnmount() {
        this.props.reset();
    }

  render() {

    return (

      <div className="auth__main">
        <div className="auth__box-container">
          <h3 className="auth__title">Login</h3>
          <div>
            <Form onSubmit={this.props.login} values={this.props.item}>
            </Form>
            {this.props.loading && (
              <Row>
                <Col>
                  <CircularProgress />
                </Col>
              </Row>
            )}
            {this.props.error && (
              <div style={{marginTop: "20px"}} className="alert alert-danger" role="alert">
                <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                {this.props.error}
              </div>
            )}

          </div>
          <div>
            <Link className="pull-right" to={`/recover_password`}>
              Recover Password
            </Link>
          </div>
          <div>
            <Link className="pull-right" to={`/register`}>
              Create new account
            </Link>
          </div>


          <div className="">
            <div>
              <img style={{width:"100%"}} src={logo} alt="Sphera"/>
            </div>

          </div>

        </div>

        <PhotographerInfo/>
      </div>
    );
  }
}


const mapStateToProps = state => {
    const { token, error, loading } = state.auth.login;
    return { token, error, loading };
};

const mapDispatchToProps = dispatch => ({
    login: values => dispatch(login(values)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

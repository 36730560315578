import { combineReducers } from 'redux';

import list from './list';
import create from './create';
import update from './update';
import del from './delete';
import createRealtime from './createRealtime';
import active from './active';
import listGoogleEvents from "./listGoogleEvents";

export default combineReducers({ list, create, update, del, createRealtime, active, listGoogleEvents });

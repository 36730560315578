import React, {Component} from 'react';
import {connect} from "react-redux";

import Moment from "moment";
import {extendMoment} from "moment-range";


import {reportList} from "../../actions/reports/report";
import Grid from "@material-ui/core/Grid";
import UserTable from "../../views/Reports/UserTable";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CircularProgress from "@material-ui/core/CircularProgress";
import HoursByClient from "../../views/Charts/HoursByClient";
import HoursByProject from "../../views/Charts/HoursByProject";
import HoursByUser from "../../views/Charts/HoursByUser";


const moment = extendMoment(Moment);

class ReportComponent extends Component{
  constructor(props) {
    super(props);
    this.state={
      groupBy: 'user',
      date: 'all-time',
      start: null,
      finish: null,
    }
  }

  componentDidMount() {
    this.props.reportList()
  }

  componentWillUnmount() {
  }

  handleChangeGrouping = (event) => {
    this.setState({
      groupBy: event.target.value
    })
    let dateQuery = '';
    if(this.state.start && this.state.finish){
      dateQuery = '&start=' + this.state.start + '&finish=' + this.state.finish
    }
    this.props.reportList('/trackings/report?limit=1000&groupBy=' + event.target.value + dateQuery)

  };
  handleChangeDate = (event) => {
    let range = this.calculateRange(event.target.value);

    this.setState({
      date: event.target.value,
      start: range.start,
      finish: range.finish
    })

    this.props.reportList('/trackings/report?limit=1000&groupBy=' + this.state.groupBy + '&start=' + range.start + '&finish=' + range.finish)

  };

  calculateRange(value){
    let start = new Date();
    let finish = new Date();
    if(value === 'this-week'){
      start = moment(start).startOf('isoweek').format('Y/M/D');
      finish = moment(finish).endOf('isoweek').format('Y/M/D');
    }else if(value === 'last-week'){
      start = moment(start).add(-1,'week').startOf('isoweek').format('Y/M/D');
      finish = moment(finish).add(-1,'week').endOf('isoweek').format('Y/M/D');
    }else if(value === 'this-month'){
      start = moment(start).startOf('month').format('Y/M/D');
      finish = moment(finish).endOf('month').format('Y/M/D');
    }else if(value === 'last-month'){
      start = moment(start).add(-1,'month').startOf('month').format('Y/M/D');
      finish = moment(finish).add(-1,'month').endOf('month').format('Y/M/D');
    }else if(value === 'this-year'){
      start = moment(start).startOf('year').format('Y/M/D');
      finish = moment(finish).endOf('year').format('Y/M/D');
    }else if(value === 'last-year'){
      start = moment(start).add(-1,'year').startOf('year').format('Y/M/D');
      finish = moment(finish).add(-1,'year').endOf('year').format('Y/M/D');
    }else{
      start = moment(start).add(-1,'year').startOf('year').format('Y/M/D');
      finish = moment(finish).endOf('year').format('Y/M/D');
    }
    let range = {
      start,
      finish
    }
    return range;
  }

  render() {
    return (
      <Grid container
            direction="row">
        <Grid xs={10} className="mt-5">
          <h2>Resultados {this.props.date}</h2>
          {this.props.report.loading && (
            <CircularProgress/>
          )}
          {this.props.report.retrieved && !this.props.report.loading && (
            <>
              <div>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                >
                  <Grid xs={6}>
                    {this.props.report.retrieved['hydra:member'][0].client && this.state.groupBy === 'client' && (

                      <HoursByClient report={this.props.report} date={this.state.date}/>
                    )}
                    {this.props.report.retrieved['hydra:member'][0].project && this.state.groupBy === 'project' &&(

                      <HoursByProject report={this.props.report} date={this.state.date}/>
                    )}
                    {this.props.report.retrieved['hydra:member'][0].user && this.state.groupBy === 'user' &&(

                      <HoursByUser report={this.props.report} date={this.state.date}/>
                    )}
                  </Grid>
                </Grid>
              </div>

            </>

          )
          }
          {this.props.report.retrieved && this.props.report.retrieved['hydra:member'].map((item) => {
            return(
              <div>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center">
                  <Grid xs={10}>

                    {item.user && (
                      <div>
                        <h3>{item.user[0].name}</h3>
                        <UserTable
                          report={item.report}
                          total={item.totals[0]}
                        />
                      </div>

                    )}
                    {item.team && (
                      <div>
                        <h3>{item.team[0].name}</h3>
                        <UserTable
                          report={item.report}
                          total={item.totals[0]}
                        />
                      </div>

                    )}
                    {item.project && (
                      <div>
                        <h3>{item.project[0].name}</h3>
                        <UserTable
                          report={item.report}
                          total={item.totals[0]}
                        />
                      </div>

                    )}
                    {item.client && (
                      <div>
                        <h3>{item.client[0].name}</h3>
                        <UserTable
                          report={item.report}
                          total={item.totals[0]}
                        />
                      </div>

                    )}
                  </Grid>
                </Grid>
              </div>
            )
          })}
        </Grid>
        <Grid xs={2} className="mt-5" style={{backgroundColor:"white"}}>
          <h3>Filters</h3>
          <Grid container>
            <Grid xs={12} className="mt-5">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Grouping</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.groupBy}
                  onChange={this.handleChangeGrouping}
                >
                  <MenuItem value={'user'}>User</MenuItem>
                  <MenuItem value={'project'}>Project</MenuItem>
                  <MenuItem value={'client'}>Client</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} className="mt-5">
              <FormControl component="fieldset">
                <FormLabel component="legend">Date</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={this.state.date} onChange={this.handleChangeDate}>
                  <FormControlLabel value="all-time" control={<Radio />} label="All Time" />
                  <FormControlLabel value="this-week" control={<Radio />} label="This Week" />
                  <FormControlLabel value="last-week" control={<Radio />} label="Last Week" />
                  <FormControlLabel value="this-month" control={<Radio />} label="This Month" />
                  <FormControlLabel value="last-month" control={<Radio />} label="Last Month" />
                  <FormControlLabel value="this-year" control={<Radio />} label="This Year" />
                  <FormControlLabel value="last-year" control={<Radio />} label="Last Year" />
                  <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => {

  const report = state.report.report;

  return {report}
};

const mapDispatchToProps = dispatch => ({
  reportList: (page) => dispatch(reportList(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportComponent)

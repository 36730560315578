import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Alert, Col, Row} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";

import {list, reset} from "../../actions/add-on/list";
import {updateImage as updateSelf, reset as resetUser} from "../../actions/user/updateSelf";


import AddOnCard from "../../views/AddOns/AddOnCard";
import Snackbar from "@material-ui/core/Snackbar";


class List extends Component{
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired
  }

  constructor(props) {
    super();
    this.state = {
      openedSnackbar: false
    }
  }

  componentDidMount() {
    this.props.list();
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.resetUser(this.props.eventSource);
  }

  handleChange = (event, addon, enable) => {

    //enable/disable add-on by id?
    const values = {};
    if(enable){
      values.addUserAddOn = addon.id;
    }else{
      values.removeUserAddOn = addon.id;
    }

    this.props.updateUser(values)
      .then( () => {
        this.setState({
          openedSnackbar: true
        })
      }).catch((error) => {
        console.log(error);
    })

    //TODO and is not the same add-on for user than for company

  }

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      openedSnackbar: false
    })

  }

  render() {
    return(
      <>
        <Row>
          {this.props.loading && (
            <Col lg={{size:1, offset:5}}>
              <CircularProgress />
            </Col>

          )}
            <Snackbar open={this.state.openedSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
              <Alert onClose={this.handleCloseSnackbar} severity="success">
                Action completed!
              </Alert>
            </Snackbar>

          {this.props.retrieved && this.props.retrieved['hydra:member'].map((item) => {
            const found = this.props.userInfo.userAddOns.find(element => element.addOn.id === item.id);
            console.log("found", found)
            return(
              <AddOnCard item={item} key={item.id} enabled={!!found} handleChange={(event, addon, enable) => this.handleChange(event,addon, enable)}/>
            )
          })}
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    retrieved,
    error,
    loading,
    eventSource
  } = state.addons.list;

  const userInfo = state.user.self.info;
  const updatedUserInfo = state.user.self.update;

  return {retrieved, error, loading, eventSource, userInfo, updatedUserInfo};
}

const mapDispatchToProps = (dispatch) => ({
  list: () => dispatch(list()),
  reset: (eventSource) => dispatch(reset(eventSource)),
  resetUser: (eventSource) => dispatch(resetUser(eventSource)),
  updateUser: (values) => dispatch(updateSelf(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list, reset } from '../../actions/project/list';
import Grid from "@material-ui/core/Grid";
import { reset as resetCreated } from '../../actions/project/create';
import ProjectPill from "../../views/Projects/ProjectPill";
import {Col, Row} from "shards-react";

class ListByClient extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetCreated: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.resetCreated();
    this.props.list();
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const { clientId } = this.props;
    let totalProjects = 0;

    return (
      <div>
        <div className="animated fadeIn">
          <Grid container>
            {' '}
            <Grid item xs={12}>
              {' '}
              {this.props.loading && (
                <div className="card-header-actions">
                      <span className="card-header-action">
                        <i className="fa fa-spin fa-circle-o-notch"></i>
                      </span>
                </div>
              )}
              {this.props.deletedItem && (
                <div className="alert alert-success">Borrado </div>
              )}{' '}
              {this.props.error && (
                <div className="alert alert-danger">{this.props.error}</div>
              )}
              <Row>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map((item) => {
                //let activeProject = "";
                if(item.id === this.props.selectedProjectId){
                  //activeProject = <span></span>;
                }
                if(clientId === item.client || "/clients/" + clientId === item.client){
                  totalProjects = totalProjects +1;
                  console.log(totalProjects);
                  return (
                    <Col className="col-lg mb-4">
                      <ProjectPill
                        key={item.id}
                        project={item}
                      />
                    </Col>

                  );
                }else{
                  return null;
                }

              })}

              {totalProjects === 0 && (
                <Col className="col-lg mb-12">
                  <p>No projects found, please create the first one</p>
                </Col>
              )}
              </Row>
            </Grid>{' '}
          </Grid>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
  } = state.project.list;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = (dispatch) => ({
  list: (page) => dispatch(list(page)),
  reset: (eventSource) => dispatch(reset(eventSource)),
  resetCreated: () => dispatch(resetCreated()),

});

export default connect(
  mapStateToProps, mapDispatchToProps
)(ListByClient);

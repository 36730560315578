import React from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card
} from "shards-react";


const ServicesPage = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <Col lg="12" md="12" sm="12" className="mb-4">
        <Card className="">
          <CardBody className="pt-0" style={{minHeight: "600px"}}>
            <Row className="">
              <Col sm="6" className="d-flex mb-2 mb-sm-0">
                Services Page
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default ServicesPage;


export function getHigherRole (roles){
  let isArray = Array.isArray(roles);

  if(isArray){
    const manager = roles.find(role => role === "ROLE_MANAGER");

    if(manager) return "manager";

    return "worker";
  }else {
    if(roles === "ROLE_MANAGER") return "manager";

    return "worker";
  }



}

import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Col,
  Form,
  FormInput,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
// @material-ui/core components

PiecesSection.propTypes = {
  /**
   * The pieces dataset.
   */
  pieces: PropTypes.array.isRequired,
  addPiece: PropTypes.func.isRequired
};

export default function PiecesSection(props) {

  return (
    <div>
      <h3>Solucitud de piezas</h3>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col md="2">
              <p>Número</p>
            </Col>
            <Col md="5" className="form-group">
              <p>Descripcion</p>
            </Col>
            <Col md="5">
              <p>Referencia</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                {props.pieces.map((value, index) => {
                  return(
                    <Row form key={index}>
                      <Col md="2">
                        <span>{index +1}.-</span>
                      </Col>
                      <Col md="5" className="form-group">

                        <FormInput
                          id={`description_${index}`}
                          type="string"
                          placeholder="Descripcion"
                        />
                      </Col>
                      <Col md="5">

                        <FormInput
                          id={`reference_${index}`}
                          type="string"
                          placeholder="Referencia"
                        />
                      </Col>
                    </Row>
                  )
                })}
                <Button theme="primary" className="mb-2 mr-1" onClick={() => props.addPiece()}>
                  Añadir pieza
                </Button>

              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
}

import React from "react";
import {
  Badge, Button,
  Card, CardBody, Col,
  Modal, ModalBody, ModalHeader
} from "shards-react";
import Update from "../../components/clients/Update";
import ListByClient from "../../components/projects/ListByClient";
import Create from "../../components/projects/Create";

function ClientCard(props){

  const [openEditClient, setOpenEditClient] = React.useState(false);
  const [openCreateProject, setOpenCreateProject] = React.useState(false);

  let item = props.item;

  const handleOpenEditClient = () => {
    setOpenEditClient(true);
  };

  const handleCloseEditClient = () => {
    setOpenEditClient(false);
  };

  const handleOpenCreateProject = () => {
    setOpenCreateProject(true);
  };

  const handleCloseCreateProject = () => {
    setOpenCreateProject(false);
  };

  return(
    <>
      <Col lg="3" md="6" sm="12" className="mb-4" key={item.id}>
        <Card small className="card-post card-post--1">
          <div>
            <Badge
              pill
              className={`card-post__category bg-${item.name}`}
              onClick={handleOpenEditClient}
              key={item.id}
              theme="secondary"
            >
              {item.name}
            </Badge>

          </div>


          <CardBody>
            <h5 className="card-title">
              Projects
            </h5>

            {/*<div>
              {item.projects.map((project) => {

                return (
                  <ProjectPill key={project.id}
                    project={project}
                  />
                )
              })}
            </div>
            */}
            <hr/>
            <ListByClient clientId={item.id}/>

            <hr/>
            <Button
              block
              onClick={handleOpenCreateProject}
            >
              Create Project

            </Button>
          </CardBody>
        </Card>
      </Col>
      <Modal
        open={openEditClient}
        onClose={handleCloseEditClient}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        toggle={handleCloseEditClient}
      >
        <ModalHeader toggle={handleCloseEditClient}>Edit Client {item.name}</ModalHeader>
        <ModalBody>
          <Update
            clientId={item.id}
          />
        </ModalBody>
      </Modal>

      <Modal
        open={openCreateProject}
        onClose={handleCloseCreateProject}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        toggle={handleCloseCreateProject}
      >
        <ModalHeader toggle={handleCloseCreateProject}>Create Project in {item.name}</ModalHeader>
        <ModalBody>
          <Create clientId={item.id}/>
        </ModalBody>
      </Modal>

    </>
  )
}

ClientCard.propTypes = {};

ClientCard.defaultProps = {};

export default ClientCard;

const colors = [
  '#1718FA',
  '#144BDE',
  '#2393F6',
  '#14B5DE',
  '#17FAEC',
  '#104675',
  '#6CB6F7',
  '#345775',
  '#1B74C2',
  '#075DA8',
  '#F5433B',
  '#E7F50A',
  '#9FA80F',
  '#0C5FA8',
  '#3DA5FF',
  '#A86700',
  '#F5A422',
  '#A85328',
  '#F5712F',
  '#A88B07',
  '#F5CC16',
  '#2F30F5',
  '#F5793B',
  '#A116F5'
]

export function getRandomColor(){
  const randomPosition = Math.floor((Math.random() * colors.length));
  return colors[randomPosition];
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
    class Authentication extends Component {
        componentWillMount() {
            if (!this.props.token) {
                console.log("no existe token")
                this.props.history.push('/login');
            }
        }
        componentWillUpdate(nextProps) {
            if (!nextProps.token) {
                this.props.history.push('/login');
            }
        }
        PropTypes = {
            router: PropTypes.object,
        };
        render() {
            return <ComposedComponent {...this.props} />;
        }
    }
    function mapStateToProps(state) {
        return {
          token: state.auth.login.token,
          user:  state.user.self.info
        };
    }
    return connect(mapStateToProps)(Authentication);
}

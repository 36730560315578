import React from "react";
import {connect} from "react-redux";

import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

//my components
import UpdateSelf from "./UpdateSelf";

const UserAccountDetails = ({ title, userInfo }) => {

  return(
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <UpdateSelf userId={userInfo.id} />
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );

};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

const mapStateToProps = (state, ownProps) => ({
  userInfo: state.user.self.info
})

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountDetails);

import React, {Component} from "react";
import { connect } from 'react-redux';

import {Alert, Col, Row} from "shards-react";
import UserDetails from "./UserDetails";
import UserAccountDetails from "./UserAccountDetails";
import UserAddOns from "./UserAddOns";
import {userSelf} from "../../actions/user/self";
import Snackbar from "@material-ui/core/Snackbar";

class UserAccount extends Component {

  constructor(props) {
    super();
    this.state = {
      openedSnackbar: false
    }
  }

  componentDidMount() {
    this.props.self()
  }

  render() {
    return(
      <>
        <Row>
          <Col lg="4">
            <UserDetails />
          </Col>
          <Col lg="8">
            <UserAccountDetails />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <UserAddOns/>
          </Col>
        </Row>
        {this.props.updated && (
          <Snackbar open={this.state.openedSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
            <Alert onClose={this.handleCloseSnackbar} severity="success">
              Account details successfully updated!
            </Alert>
          </Snackbar>
        )}


      </>
    )
  }
}

const mapStateToProps = (state) => {
  const updated= state.user.self.update.updated;

  return {updated};
}

const mapDispatchToProps = (dispatch) => ({
  self: () => dispatch(userSelf())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccount)

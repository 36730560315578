/**
 * Created by alejandro on 17/04/19.
 */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

class Form extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        error: PropTypes.string,
        token: PropTypes.string
    };

    componentDidMount() {
      this.props.initialize({ change_password_token: this.props.token });
    }

    renderField = data => {
        data.input.className = 'form-control';

        const isInvalid = data.meta.touched && !!data.meta.error;
        if (isInvalid) {
            data.input.className += ' is-invalid';
            data.input['aria-invalid'] = true;
        }

        if (this.props.error && data.meta.touched && !data.meta.error) {
            data.input.className += ' is-valid';
        }
        return (
            <div className={`form-group`}>
                <label
                    htmlFor={`auth_${data.input.name}`}
                    className="form-control-label"
                >
                    {data.label}
                </label>
                <input
                    {...data.input}
                    type={data.type}
                    step={data.step}
                    required={data.required}
                    id={`change_password_${data.input.name}`}
                />
                {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
            </div>
        );
    };

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <Field
                    component={this.renderField}
                    label="Password"
                    name="password"
                    type="password"
                    required={true}
                />
                <Field
                    component={this.renderField}
                    label="Repeat password"
                    name="repassword"
                    type="password"
                    required={true}
                />

                <button type="submit" className="btn btn-primary btn-block">
                    Cambiar contraseña
                </button>
            </form>
        );
    }

}


export default reduxForm({
    form: 'change_password',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(Form);

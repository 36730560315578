import React, { Component } from 'react';
import Form from './Form'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change_password, reset } from '../../../actions/auth/changepassword';
import logo from '../../../images/brand/logo_black.png';
import {Link} from "react-router-dom";

class ChangePassword extends Component {

    static propTypes = {
        error: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        token: PropTypes.string,
        login: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    componentDidMount(){
      console.log('props match token');
      console.log(this.props.match.params.token);

    }
    componentWillUnmount() {
        this.props.reset();
    }

  render() {

    return (

      <div className="auth__main">
        <div className="auth__box-container">
          <h3 className="auth__title">Change Password</h3>
          <div>
            {!this.props.loading && !this.props.success && (
              <div>
                <Form onSubmit={this.props.change_password} values={this.props.item} token={this.props.match.params.token}/>
                <Link className="link" to={`/login`}>
                  Back to Login
                </Link>
              </div>


            )}
            {this.props.loading && (
              <h2 className="card-header-action text-center mt-3 mb-0" ><i className="fa fa-spin fa-circle-o-notch"></i></h2>
            )}
            {this.props.error && (
              <div style={{marginTop: "20px"}} className="alert alert-danger" role="alert">
                <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                {this.props.error}
              </div>
            )}
            {this.props.success && (
              <div>
                <div className="alert alert-info" role="alert">
                  <span className="" aria-hidden="true" />{' '}
                  <p>Password changed successfully.</p>
                </div>
                <Link className="link" to={`/login`}>
                  Back to login
                </Link>
              </div>
            )}

          </div>

          <div className="">
            <div>
              <img style={{width:"100%"}} src={logo} alt="Sphera"/>
            </div>

          </div>

        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
    const { token, success, error, loading } = state.auth.changepassword;
    const user = state.user.self.info;
    return { token, error, loading, user, success };
};

const mapDispatchToProps = dispatch => ({
    change_password: values => dispatch(change_password(values)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);

import {AppointmentForm} from "@devexpress/dx-react-scheduler-material-ui";
import React from "react";

const AppointmentFormBasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
  const onCustomFieldChange = (nextValue) => {
    onFieldChange({ customField: nextValue });
  };

  const onExecuteSave = (e) => {
    console.log('onExecuteSave', e)
  }

  const getMessage = (e) => {
    console.log("GET MESSAGE", e);

  }

  return (
    <AppointmentForm.BasicLayout
      appointmentData={appointmentData}
      onFieldChange={onFieldChange}
      {...restProps}
    >

      {/*<AppointmentForm.Label
        text="Pere Field"
        type="title"
      />
      <AppointmentForm.TextEditor
        value={appointmentData.customField}
        onValueChange={onCustomFieldChange}
        placeholder="Custom field"
      />
      <AppointmentForm.CommandButton
        id="saveButton"
        onExecute={onExecuteSave}
        getMessage={getMessage}
      />*/}
    </AppointmentForm.BasicLayout>
  );
};

export default AppointmentFormBasicLayout;

/**
 * Created by pere on 22/09/20.
 */
import { combineReducers } from 'redux';

export function error(state = null, action) {
    switch (action.type) {
        case 'AUTH_REGISTER_ERROR':
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'AUTH_REGISTER_LOADING':
            return action.loading;

        default:
            return state;
    }
}

export function flag(state = null, action) {
    switch (action.type) {
        case 'AUTH_REGISTER_SUCCESS':
            return action.flag;

        default:
            return state;
    }
}

export function pathname_redirect(state = null, action) {
  switch (action.type) {
    case 'AUTH_REGISTER_SUCCESS_REDIRECT':
      return action.token;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, flag, pathname_redirect });

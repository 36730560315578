import React from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import Update from "../../components/users/Update";
import Avatar from "@material-ui/core/Avatar";
import {getHigherRole} from "../../utils/roles";

function UserCard(props){

  const [openEditUser, setOpenEditUser] = React.useState(false);

  let item = props.item;

  const handleOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  return(
    <>
      <Col lg="3" md="6" sm="12" className="mb-4" key={item.id}>
        <Card small className="card-post card-post--1">
          <div>
            <Badge
              pill
              className={`card-post__category bg-${item.name}`}
              onClick={handleOpenEditUser}
              key={item.id}
            >
              {item.name}
            </Badge>

          </div>
          <div className="pl-3 pt-4">
            <Avatar src={item.profileImageFilename}>{item.name.charAt(0).toUpperCase()}{item.lastName ? item.lastName.charAt(0).toUpperCase() : ""}</Avatar>
          </div>

          <CardBody>
            <p>{item.email}</p>
            <p>{getHigherRole(item.roles)}</p>
          </CardBody>
        </Card>
      </Col>
      <Modal
        open={openEditUser}
        onClose={handleCloseEditUser}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        toggle={handleCloseEditUser}
      >
        <ModalHeader toggle={handleCloseEditUser}>Edit User {item.name}</ModalHeader>
        <ModalBody>
          <Update
            userId={item.id}
          />
        </ModalBody>
      </Modal>

    </>
  )
}

UserCard.propTypes = {};

UserCard.defaultProps = {};

export default UserCard;

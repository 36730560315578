import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";

const NavbarMenu = ({ contained, menuItems, copyright }) => (
  <footer className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    <Container fluid={contained}>
      <Row>
        <Nav>
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={Link} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Row>
    </Container>
  </footer>
);

NavbarMenu.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

NavbarMenu.defaultProps = {
  contained: false,
  copyright: "Copyright © 2018 DesignRevision",
  menuItems: [
    {
      title: "Home",
      to: "/home"
    },
    {
      title: "Services",
      to: "/services"
    },
    {
      title: "About",
      to: "/about"
    },
    {
      title: "Blog",
      to: "#"
    }
  ]
};

export default NavbarMenu;

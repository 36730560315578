/**
 * Created by pere on 22/09/20.
 */
import { combineReducers } from 'redux';
import update from './updateSelf';

export function info(state = null, action) {
  switch (action.type) {

    case 'USER_UPDATE':
      return action.user;

    default:
      return state;
  }
}

export function notifications(state = null, action) {
  switch (action.type) {

    case 'USER_ALERT_MERCURE_MESSAGE':
      return action.retrieved;

    case 'USER_ALERT_MERCURE_OPEN':
      return action.eventSource;

    case 'USER_ALERT_RESET':
      return null;

    default:
      return state;
  }
}



export default combineReducers({ info, notifications, update });

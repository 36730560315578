import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {list, reset} from "../../actions/client/listSelect";
import {
  Col, DropdownItem
} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";

class ShortList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetCreated: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
    }
  }

  componentDidMount() {
    //this.props.resetCreated();
    this.props.list();
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {


    return (
      <>
        {this.props.loading && (
          <Col>
            <CircularProgress />
          </Col>

        )}
          {this.props.retrieved &&
          this.props.retrieved['hydra:member'].map((item) => {
            let list = item.projects.map((project) => {
              return(
                <DropdownItem
                  style={{color: project.color, fontSize: "15px"}}
                  onClick={()=>this.props.onSelectProject(project.name, project.id, project)}
                >
                  {project.name}
                </DropdownItem>
              )
            })
            return(
              <>
                <b><p className="mb-0 ml-1 text-uppercase" style={{cursor: "not-allowed"}}>{item.name}</p></b>
                {list}
                <DropdownItem divider/>
              </>

            )
          })}

      </>

    );
  }

}

const mapStateToProps = (state) => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
  } = state.client.listSelect;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = (dispatch) => ({
  list: (page) => dispatch(list(page)),
  //resetCreated: () => dispatch(resetCreated()),
  reset: (eventSource) => dispatch(reset(eventSource)),

});

export default connect(
  mapStateToProps, mapDispatchToProps
)(ShortList);

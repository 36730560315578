import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';

// import reducers
import auth from './reducers/auth/';
import user from './reducers/user/';
import company from './reducers/company/';
import tracking from './reducers/tracking/';
import project from './reducers/project/';
import client from './reducers/client/';
import report from './reducers/reports/';
import addons from './reducers/add-on';

import { reducer as form } from 'redux-form';


//TODO remove compose enhance for production and logger

const composeEnhance =   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    /* Add your reducers here */
    form,
    auth,
    user,
    tracking,
    project,
    client,
    report,
    company,
    addons
  }),
  composeEnhance(
    applyMiddleware(routerMiddleware(history), thunk)
  )
);

export {history};
export default store ;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Card,
  CardHeader,
  ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader,
} from "shards-react";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { self } from '../../actions/company/self';
import {updateImage, reset} from "../../actions/company/update";

class CompanyDetails extends Component{

  static propTypes = {
    self: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string
  }

  constructor(props) {
    super();
    this.state = {
      openImageModal: false,
      image: null
    }

    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.sendImage = this.sendImage.bind(this);
    this.handleCloseImageModal = this.handleCloseImageModal.bind(this);
    this.handleOpenImageModal = this.handleOpenImageModal.bind(this);
    this.setImageState = this.setImageState.bind(this);
  }

  componentDidMount() {
    this.props.self();
  }

  componentWillUnmount() {

    this.props.reset(this.props.companyUpdatedInfo.eventSource);
  }

  handleOpenImageModal(){
    this.setState({
      openImageModal: true
    })
  }

  handleCloseImageModal(){
    this.setState({
      openImageModal: false
    })
  }

  setImageState(image){
    this.setState({
      image: image
    });
  }
  handleChangeImage = (evt) => {
    var reader = new FileReader();
    var file = evt.target.files[0];


    reader.onload = (upload) => {
      this.setImageState(upload.target.result);
    };


    reader.readAsDataURL(file);
  }

  sendImage = () => {
    let values = {};
    values.imageFile = this.state.image;
    this.props.updateImage(values).then(()=>{
      this.setState({
        openImageModal: false
      })
    });

  }

  render() {

    const {companyInfo, companyUpdatedInfo} = this.props;

    return (

      <Card small className="mb-4 pt-3">
        {companyInfo && (
          <>
            <CardHeader className="border-bottom text-center">
              <div className="mb-3 mx-auto">

                {companyInfo && (
                  <>
                    <Avatar
                      style={{margin: "auto", width: "80px", height: "80px", cursor: "pointer"}}
                      src={companyUpdatedInfo.updated ? companyUpdatedInfo.updated.profileImageFilename : companyInfo.profileImageFilename}
                      onClick={this.handleOpenImageModal}
                    >
                      {companyInfo.name.charAt(0).toUpperCase()}
                    </Avatar>
                    <Modal
                      open={this.state.openImageModal}
                      onClose={this.handleCloseImageModal}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      toggle={this.handleCloseImageModal}
                    >
                      <ModalHeader toggle={this.handleCloseImageModal}>Change Image</ModalHeader>
                      <ModalBody>
                        {companyUpdatedInfo && companyUpdatedInfo.updateLoading && (
                          <CircularProgress />
                        )}
                        {!companyUpdatedInfo.updateLoading && (
                          <>
                            <div className="mb-3 mx-auto">
                              <Avatar
                                style={{margin: "auto", width: "80px", height: "80px", cursor: "pointer"}}
                                src={this.state.image ? this.state.image : companyUpdatedInfo.updated ? companyUpdatedInfo.updated.profileImageFilename : companyInfo.profileImageFilename}
                              >
                                {companyInfo.name.charAt(0).toUpperCase()}
                              </Avatar>

                            </div>
                            <div className="custom-file mb-3">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="profileImageFilename"
                                onChange={this.handleChangeImage}
                              />
                              <label className="custom-file-label" htmlFor="customFile2">
                                Choose file...
                              </label>
                            </div>
                            <Button onClick={this.sendImage} block theme="success">
                              Send
                            </Button>
                          </>
                        )}

                      </ModalBody>
                    </Modal>
                  </>

                )}


              </div>

            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="px-4">
                <div className="progress-wrapper">
                  <strong className="text-muted d-block mb-2">
                    {companyInfo.name}
                  </strong>

                </div>
              </ListGroupItem>
              {/**<ListGroupItem className="px-4">
               <div className="progress-wrapper">
               <strong className="text-muted d-block mb-2">
               {userDetails.performanceReportTitle}
               </strong>
               <Progress
               className="progress-sm"
               value={userDetails.performanceReportValue}
               >
               <span className="progress-value">
               {userDetails.performanceReportValue}%
               </span>
               </Progress>
               </div>
               </ListGroupItem>
               <ListGroupItem className="p-4">
               <strong className="text-muted d-block mb-2">
               {userDetails.metaTitle}
               </strong>
               <span>{userDetails.metaValue}</span>
               </ListGroupItem>**/}
            </ListGroup>
          </>
        )}

      </Card>
    )
  }

}

const mapStateToProps = (state) => {
  const companyInfo = state.company.self.info;
  const companyUpdatedInfo = state.company.update;

  return {companyInfo, companyUpdatedInfo };
}

const mapDispatchToProps = (dispatch) => ({
  self: () => dispatch(self()),
  updateImage: (values) => dispatch(updateImage(values)),
  reset: (eventSource) => dispatch(reset(eventSource)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetails);

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./shards-dashboard/styles/styles.css";

import no_require_auth from "./components/auth/no_require_auth";
import require_auth from "./components/auth/require_auth";

import {DefaultLayout} from "./layouts";
import Login from "./components/login/Login";
import RecoverPassword
  from "./components/login/RecoverPassword/RecoverPassword";
import ChangePassword from "./components/login/ChangePassword/ChangePassword";

import Register from "./components/register/Register";

export default function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route
            exact
            path="/login"
            component={no_require_auth(Login)}
          />
          <Route
            exact
            path="/register"
            component={no_require_auth(Register)}
          />
          <Route
            exact
            path="/recover_password"
            component={no_require_auth(RecoverPassword)}
          />
          <Route
            exact
            path="/change_password/:token"
            component={no_require_auth(ChangePassword)}
          />
          <Route
            path="/"
            component={require_auth(DefaultLayout)}
          />
        </Switch>
      </div>
    </Router>
  );
}

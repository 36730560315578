import React, {Component} from "react";
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Col,
  Dropdown, DropdownToggle, DropdownMenu
} from "shards-react";

//components
import ShortList from "../../clients/ShortList";
import TimeCounter from "../../../views/Commons/TimeCounter";

//actions
import { create, reset } from '../../../actions/tracking/createRealtime';
import {list} from "../../../actions/tracking/active";

class NavbarTimer extends Component{

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.state = {
      open: false,
      selectedProjectName: null,
      selectedProjectId: null,
      selectedProject: null,
      title: null,
    };
  }

  componentDidMount() {
    this.props.retrieveActive();
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  onSelectProject (name,id, project){
    this.setState({
      selectedProjectName: name,
      selectedProjectId: id,
      selectedProject: project,
    })
  }

  sendForm(){

    let action = "start";
    if(this.props.activeRetrieved) action="stop";

    let values = {};

    if(action === "start"){
      if(!this.state.selectedProject){
        return Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Project is required',
            showConfirmButton: false,
            timer: 3500
          }
        )
      }

      if(!this.state.title){
        return Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Description is required',
            showConfirmButton: false,
            timer: 3500
          }
        )
      }

      values = {
        "project": "/projects/" + this.state.selectedProjectId,
        "title": this.state.title
      };


    }else{
      this.setState({
        selectedProjectName: null,
        selectedProjectId: null,
        selectedProject: null,
        title: null
      })
    }

    //create tracking calling to api

    this.props.create(action, values).then(() => {
      this.props.retrieveActive();
    })

  }

  handleChangeDescription(event){
    this.setState({
      title: event.target.value
    })

  }

  render(){

    const projectName = this.props.activeRetrieved ? this.props.activeRetrieved.project[0].name : this.state.selectedProjectName ? this.state.selectedProjectName : "Choose project";

    return (
      //d-none es lo que hace que no aparezca el timer en el menu
      //el problema es que si aparece se descuadra tot
      <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
        <Col lg={7} className="pt-3">
          <InputGroup seamless className="ml-3">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">description</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              className="navbar-search"
              placeholder="Task description..."
              value={this.props.activeRetrieved ? this.props.activeRetrieved.title : this.state.title ? this.state.title : ""}
              onChange={this.handleChangeDescription}
            />
          </InputGroup>
        </Col>

        <Col lg={2} className="pt-3">
          <Dropdown open={this.state.open} toggle={this.toggle}>
            {!this.props.activeRetrieved ? (
              <>
                <DropdownToggle
                  caret
                  style={{backgroundColor: "#007bff"}}
                >
                  <span className="pr-2">{(projectName.length > 15) ? projectName.substring(0, 12) + '...' : projectName}</span>
                </DropdownToggle>
                {!this.props.activeRetrieved && (
                  <DropdownMenu
                    className="shortProjectList-menu"
                  >
                    <ShortList
                      onSelectProject={this.onSelectProject}
                    />
                  </DropdownMenu>

                )}
              </>
            ):(
              <DropdownToggle
                style={{backgroundColor: this.props.activeRetrieved.project[0].color}}
              >
                <span className="pr-2">{(projectName.length > 15) ? projectName.substring(0, 12) + '...' : projectName}</span>
              </DropdownToggle>
              )}

          </Dropdown>
        </Col>

        <Col lg={2} className="pt-3 text-center">
          <TimeCounter start={this.props.activeRetrieved ? this.props.activeRetrieved.startDate : null}/>
        </Col>
        <Col lg={1} className="pt-4">
          <i style={{cursor: "pointer", fontSize:"large"}} onClick={this.sendForm} className="material-icons">{this.props.activeRetrieved ? "stop" : "play_circle_filled_white"}</i>
        </Col>
      </Form>
    )
  }
}

const mapStateToProps = (state) => {
  const { created, error, loading } = state.tracking.createRealtime;
  const activeError = state.tracking.active.error;
  const activeLoading = state.tracking.active.loading;
  const activeRetrieved = state.tracking.active.retrieved;
  const activeEventSource = state.tracking.active.eventSource;

  return { created, error, loading, activeError, activeLoading, activeRetrieved, activeEventSource };
};

const mapDispatchToProps = (dispatch) => ({
  create: (action, values) => dispatch(create(action, values)),
  retrieveActive: () => dispatch(list()),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarTimer);

/**
 * Created by pere on 22/09/20.
 */
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'AUTH_REGISTER_ERROR', error };
}

export function loading(loading) {
  return { type: 'AUTH_REGISTER_LOADING', loading };
}

export function success(flag) {
  return { type: 'AUTH_REGISTER_SUCCESS', flag };
}

export function pathnameToRedirectAfterLogin(pathname) {
  return { type: 'AUTH_REGISTER_SUCCESS_REDIRECT', pathname };
}

export function register(values) {
  return (dispatch) => {
    dispatch(loading(true));
    dispatch(error(null));

    return fetch('/register', {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then((retrieved) => {
        dispatch(loading(false));
        dispatch(success(true));
      })
      .catch((e) => {
        dispatch(loading(false));

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}

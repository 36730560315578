import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import {Appointments} from "@devexpress/dx-react-scheduler-material-ui";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "absolute",
    top: "3px",
    right: "10px"
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  project: {
    textAlign: "right",
    marginRight:"10px",
    mixBlendMode:"hard-light",
    position: "absolute",
    top: "3px",
    right: "1px"
  }
}));

const dragDisableIds = new Set(["Google"]);

const allowDrag = ({ eventtype }) => !dragDisableIds.has(eventtype);

const appointmentLayout = (props) => {
  const classes = useStyles();

  const project = props.resources[0] ? <Chip className={classes.project} size="small" label={(props.resources[0].text.length > 10) ? props.resources[0].text.substring(0, 7) + '...' : props.resources[0].text}/> : "";
  const googleLogoUrl = 'https://res.cloudinary.com/pasproduccions/image/upload/v1614072215/AddOns/yxa5ervl7p90ifpjz0n5.png';
  const eventtype = props.data.eventtype === "Google" ? <Avatar src={googleLogoUrl} className={classes.small}>GC</Avatar> : "";
  if (allowDrag(props.data)) {
    return <Appointments.Appointment {...props} >{project} {eventtype} {props.children} </Appointments.Appointment>;
  } return <Appointments.Appointment {...props} style={{ ...props.style, cursor: 'not-allowed' }} >{project} {eventtype} {props.children}</Appointments.Appointment>;
};

export default appointmentLayout;

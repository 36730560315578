import React from "react";
import { withStyles } from '@material-ui/core/styles';

import {
  Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = {
  toolbarRoot: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
};

const ToolbarWithLoading = withStyles(styles, { name: 'Toolbar' })(
  ({ children, classes, ...restProps }) => (
    <div className={classes.toolbarRoot}>
      <Toolbar.Root {...restProps}>
        {children}
      </Toolbar.Root>
      <LinearProgress className={classes.progress} />
    </div>
  ),
);

export default ToolbarWithLoading;

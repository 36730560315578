import { combineReducers } from "redux";

export function error(state = null, action) {
  switch (action.type) {
    case 'ADDON_LIST_ERROR':
      return action.error;

    case 'ADDON_LIST_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {

  switch (action.type) {
    case 'ADDON_LIST_LOADING':
      return action.loading;

    case 'ADDON_LIS_RESET':
      return false;

    default:
      return state;

  }

}

export function retrieved(state = null, action) {

  switch (action.type) {
    case 'ADDON_LIST_SUCCESS':
      return action.retrieved;

    case 'ADDON_LIST_RESET':
      return null;

    default:
      return state;

  }

}

export function eventSource(state = null, action) {

  switch (action.type) {
    case 'ADDON_LIST_RESET':
      return null;

    default:
      return state;

  }

}

export default combineReducers({error, loading, retrieved, eventSource});

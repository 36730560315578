/**
 * Created by pere on 22/09/20.
 */
import { fetch } from '../../utils/dataAccess';
import { updateFromToken } from '../user/self';

export function error(error) {
  return { type: 'AUTH_LOGIN_ERROR', error };
}

export function loading(loading) {
  return { type: 'AUTH_LOGIN_LOADING', loading };
}

export function success(token) {
  return { type: 'AUTH_LOGIN_SUCCESS', token };
}

export function pathnameToRedirectAfterLogin(pathname) {
  return { type: 'AUTH_LOGIN_SUCCESS_REDIRECT', pathname };
}

export function login(values) {
  values.roles=[values.roles];
  return (dispatch) => {
    dispatch(loading(true));
    dispatch(error(null));

    return fetch('/authentication_token', {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then((response) => {
        dispatch(loading(false));
        return response.json();
      })
      .then((retrieved) => {
        dispatch(updateFromToken(retrieved.token));
        dispatch(success(retrieved.token));
        localStorage.setItem('token', retrieved.token);
      })
      .catch((e) => {
        dispatch(loading(false));
        let errorMessage = '';
        if (e.message === 'Unauthorized') {
          errorMessage = 'The username or password is incorrect';
        } else if (e.message === 'Account is disabled.') {
          errorMessage =
            'This user has been disabled. Please check with your Team manager';
        } else {
          errorMessage = 'The username or password is incorrect';
        }
        /*if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }*/

        dispatch(error(errorMessage));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}

import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import routes from "../routes";

let _store = {
  menuVisible: false,
  navItems: routes
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    console.log(_store.navItems)
    return _store.navItems;
  }

  addChangeListener(callback) {
    console.log("addChangeListener")
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    console.log("removeChangeListener")
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();

/**
 * Created by pere on 22/09/20.
 */
import { combineReducers } from 'redux';

import self from './self';
import list from './list';
import create from './create';
import update from './update';
import del from './delete';


export default combineReducers({
  self,
  list,
  create,
  update,
  del
});

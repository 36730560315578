/**
 * Created by pere on 22/09/20.
 */
import { combineReducers } from 'redux';
import login from './login';
import recoverpassword from './recoverpassword';
import changepassword from './changepassword';
import register from './register';

export default combineReducers({ login, recoverpassword, changepassword, register});

import React from "react";
import {Card, Col, Nav, Row} from "shards-react";

import { Store } from "../../../flux";

import SidebarNavItem from "./SidebarNavItem";
import Avatar from "@material-ui/core/Avatar";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);

  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }


  render() {
    const { navItems: items } = this.state;
    const userInfo = this.props.user;
    const companyInfo = this.props.company;
    const companyImage = companyInfo.update.updated ? companyInfo.update.updated.profileImageFilename : companyInfo.self.info ? companyInfo.self.info.profileImageFilename : userInfo.company.profileImageFilename;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((route, idx) => {
            //Done to fix blank screen when load webpage => check if this.props.user exists
            if(this.props.user){
              if(route.sidebarVisible){
                for(let role in route.roles){
                  for (let user_role in this.props.user.roles) {
                    if (route.roles[role] === this.props.user.roles[user_role]) {
                      return (
                        <SidebarNavItem key={idx} item={route} />
                      )
                    }
                  }
                }

              }else{
                return null;
              }
            }

          })
          }
          {this.props.company && this.props.company.self.info && (
            <div className="workspace-menu">
              <div className="mb-3 mx-auto">
                <p className="font-weight-bolder mb-1">Workspace:</p>
                <Card>
                  <Row className="mt-1 ml-1">
                    <Col className="col-2">

                      <Avatar
                        style={{margin: "auto", width: "40px", height: "40px"}}
                        src={companyImage}
                      >
                        {companyInfo.self.info.name.charAt(0).toUpperCase()}
                      </Avatar>

                    </Col>
                    <Col className="col-8 offset-1">
                      <p className="font-weight-bold">{companyInfo.self.info.name}</p>
                    </Col>
                  </Row>
                </Card>


              </div>

            </div>
          )}


        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;

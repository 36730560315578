import React from "react";
import {
  Container, Row,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import List from "../../components/users/List";

const Users = () => (
  <div style={{minHeight: "900px"}}>
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Usuarios" subtitle="Usuarios" className="text-sm-left mb-3" />
      </Row>
      <List/>

    </Container>

  </div>
);

Users.propTypes = {};

Users.defaultProps = {};

export default Users;

import { combineReducers } from 'redux';


export function authenticationError(state = null, action) {
  switch (action.type) {
    case 'USER_GOOGLE_AUTHENTICATION_ERROR':
      return action.updateError;

    default:
      return state;
  }
}

export function authenticationLoading(state = false, action) {
  switch (action.type) {
    case 'USER_GOOGLE_AUTHENTICATION_LOADING':
      return action.updateLoading;

    default:
      return state;
  }
}

export function authenticated(state = null, action) {
  switch (action.type) {
    case 'USER_GOOGLE_AUTHENTICATION_SUCCESS':
      return action.updated;

    default:
      return state;
  }
}

export default combineReducers({
  authenticationError,
  authenticationLoading,
  authenticated
});

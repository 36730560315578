import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'TRACKING_REALTIME_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'TRACKING_REALTIME_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'TRACKING_REALTIME_CREATE_SUCCESS', created };
}

export function create(action, values) {
  console.log(values);
  return dispatch => {
    dispatch(loading(true));
    return fetch('/trackings/self/' + action, { method: 'POST', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));
        return response.json();
      })
      .then(retrieved => {
        if(action === "start"){
          dispatch(success(retrieved))
        }else{
          dispatch(success(null))
        }

      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
  };
}

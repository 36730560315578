import React, {useState} from "react";
import {connect} from "react-redux";

import PropTypes from "prop-types";
import {
  Badge,
  Card, CardBody,
  CardHeader, Col,
  Row,
} from "shards-react";
import Avatar from "@material-ui/core/Avatar";
import {userSelf} from "../../actions/user/self";

const UserAddOns = ({userInfo, userUpdatedInfo, calendarListUpdate, self, ...props}) => {


  return(
    <>
      <Card small className="mb-4 pt-3">
        <CardHeader className="border-bottom text-center">
          <h4 className="mb-0">Add Ons</h4>

        </CardHeader>

      </Card>
      <Row>
        {userInfo.userAddOns && userInfo.userAddOns.map((item) => {
          console.log('userInfo addons', item);
          return(
            <Col lg="4">
              <Card small className="card-post card-post--1">

                <div>
                  <Badge
                    pill
                    className={`card-post__category bg-${item.addOn.name}`}
                    key={item.id}
                  >
                    {item.addOn.name}
                  </Badge>

                </div>
                <div className="pl-3 pt-4">
                  <Avatar src={item.addOn.image}>{item.addOn.name.charAt(0).toUpperCase()}</Avatar>
                </div>

                <CardBody>
                  {item.status === "pending" && (
                    <div>
                      <p>Needs more configuration</p>
                      {userInfo.authUrl && userInfo.authUrl !== "authorized" && (
                        <>
                          <p>Tenemos url</p>
                          <a href={userInfo.authUrl}>Press here</a>
                        </>

                      )}
                      {userInfo.authUrl && userInfo.authUrl === "authorized" && (
                        <>
                          <p>Tenemos url</p>
                          <a href="/google_calendars/configuration">Google Calendars Configuration</a>
                        </>

                      )}
                    </div>

                  )}

                  {item.status === "success" && (
                    <>
                      <p>Account connected</p>
                      <a href="/google_calendars/configuration">Google Calendars Configuration</a>
                    </>


                  )}

                </CardBody>
              </Card>
            </Col>
          )
        })}


      </Row>


    </>

    )

};

UserAddOns.propTypes = {
  userInfo: PropTypes.object.isRequired,
  userUpdatedInfo: PropTypes.object
};

UserAddOns.defaultProps = {

};


const mapStateToProps = (state, ownProps) => ({
  userInfo: state.user.self.info,
  userUpdatedInfo: state.user.self.update,
})

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    self: () => dispatch(userSelf())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserAddOns);

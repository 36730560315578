import {
  fetch,
  normalize,
  normalizeCustom,
  extractHubURL,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'TRACKING_SELF_ACTIVE_ERROR', error };
}

export function loading(loading) {
  return { type: 'TRACKING_SELF_ACTIVE_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'TRACKING_SELF_ACTIVE_SUCCESS', retrieved };
}

export function list(page = '/trackings/self/active') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        console.log(retrieved["@type"]);
        if(retrieved["@type"] == "hydra:Error"){
          dispatch(success(null));
        }else{
          retrieved = normalizeCustom(retrieved);


          dispatch(success(retrieved));

          if (hubURL)
            dispatch(
              mercureSubscribe(
                hubURL,
                retrieved['hydra:member'].map(i => i['@id'])
              )
            );
        }


      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'TRACKING_SELF_ACTIVE_RESET' });
  };
}

export function mercureSubscribe(hubURL, topics) {
  return dispatch => {
    const eventSource = subscribe(hubURL, topics);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource) {
  return { type: 'TRACKING_SELF_ACTIVE_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'TRACKING_SELF_ACTIVE_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'TRACKING_SELF_ACTIVE_MERCURE_MESSAGE', retrieved });
  };
}

import React from "react";
import classNames from "classnames";
import { Col } from "shards-react";

const Slider = ({ ...attrs }) => {
  const classes = classNames(
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="12" className={classes} { ...attrs }>
      <div className="slider">
        <h1>Welcome to Recambiauto</h1>
      </div>
    </Col>
  )
};

Slider.propTypes = {

};

export default Slider;


import {success} from "./login"
import {info} from "../user/self"
export function logout() {
  return dispatch => {
    localStorage.clear();
    dispatch(success(null));
    dispatch(info(null));
  }
}

import React from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
} from "shards-react";
import Avatar from "@material-ui/core/Avatar";
import Switch from "@material-ui/core/Switch";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";

function AddOnCard(props){
  const [state, setState] = React.useState({
    checked: props.enabled
  });
  let item = props.item;

  const handleChange = (event) => {
    props.handleChange(event, item, !state.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return(
    <>
      <Col lg="3" md="6" sm="12" className="mb-4" key={item.id}>
        <Card small className="card-post card-post--1">

          <div>
            <Badge
              pill
              className={`card-post__category bg-${item.name}`}
              key={item.id}
            >
              {item.name}
            </Badge>

          </div>
          <div className="pl-3 pt-4">
            <Avatar src={item.image}>{item.name.charAt(0).toUpperCase()}</Avatar>
          </div>

          <CardBody>
            {!item.available && (
              <p>Not available...Cooming soon!</p>
            )}
            {item.available && (
              <div>
                <p>{item.description}</p>
                <Grid container justify="flex-end">
                  <Switch
                    checked={props.enabled}
                    onChange={handleChange}
                    color="primary"
                    name="checked"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>

              </div>
            )}

          </CardBody>
        </Card>
      </Col>

    </>
  )
}

AddOnCard.propTypes = {
  item: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
};

AddOnCard.defaultProps = {};

export default AddOnCard;

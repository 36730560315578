import React from "react";
import {connect} from 'react-redux';

import Swal from 'sweetalert2';

import Paper from "@material-ui/core/Paper";

import {
  Container, Row,
} from "shards-react";

import {
  EditingState, GroupingState, IntegratedEditing, IntegratedGrouping,
  ViewState
} from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  DayView,
  Appointments,
  WeekView,
  Toolbar,
  ViewSwitcher,
  MonthView,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  EditRecurrenceMenu,
  Resources,
  CurrentTimeIndicator,
  ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';

import DayScaleCell from './DayScaleCell';
import AppointmentFormBasicLayout from "./AppointmentFormBasicLayout";


import PageTitle from "../../components/common/PageTitle";

import { owners } from './demo-data/tasks';
import { appointments, resourcesData } from './demo-data/resources';

//import actions
import {list as listTrackings} from "../../actions/tracking/list";
import {create} from "../../actions/tracking/create";
import {update} from "../../actions/tracking/update";
import {del} from "../../actions/tracking/delete";
import {list as listProjects} from "../../actions/project/list";
import RecurrenceLayout from "./RecurrenceLayout";
import active from "../../reducers/tracking/active";
import {list as activeTracking} from "../../actions/tracking/active";
import {listGoogleEvents} from "../../actions/tracking/listGoogleEvents"
import ToolbarWithLoading from "./ToolbarLoading";
import {checkAddOnEnabledByCName} from "../../utils/commonFunctions";
import Avatar from "@material-ui/core/Avatar";
import appointmentLayout from "./AppointmentLayout";

const SHIFT_KEY = 16;

const dragDisableIds = new Set([3, 8, 10, 12]);

const allowDrag = ({ id }) => !dragDisableIds.has(id);

const  appointmentComponent = (props) => {
  console.log("PROPS",props);
  const project = <p style={{textAlign: "right", marginRight:"10px", mixBlendMode:"hard-light"}}>{props.resources[0] ? props.resources[0].text : ""}</p>;
  const googleLogoUrl = 'https://res.cloudinary.com/pasproduccions/image/upload/v1614072215/AddOns/yxa5ervl7p90ifpjz0n5.png';
  const eventtype = props.data.eventtype === "Google" ? <Avatar src={googleLogoUrl}>GC</Avatar> : "";
  if (allowDrag(props.data)) {
    return <Appointments.Appointment {...props} >{eventtype} {props.children} {project}</Appointments.Appointment>;
  } return <Appointments.Appointment {...props} style={{ ...props.style, cursor: 'not-allowed' }} >{eventtype} {props.children} {project}</Appointments.Appointment>;
};

const WeekLayout = ({ ...restProps }) => {

  return (
    <WeekView.Layout
      {...restProps}
    >
      <WeekView.DayScaleEmptyCell
        text="Pere Field"
        type="title"
      >
        <p>hola</p>
      </WeekView.DayScaleEmptyCell>
    </WeekView.Layout>
  );
};

const renderTimeCell = (data) => <i style='color: green'>hola</i>;

const renderResourceCell = (model) => {
  return (
    <i style={{color: "blue"}}>g{model.data.text}</i>
  );
}

const TimeTableCell = (props) => {
  const { startDate } = props;
  const date = new Date(startDate);

  if (date.getDate() === new Date().getDate()) {
    return <WeekView.TimeTableCell {...props}  >Hola</WeekView.TimeTableCell>;
  } if (date.getDay() === 0 || date.getDay() === 6) {
    return <WeekView.TimeTableCell {...props}  />;
  } return <WeekView.TimeTableCell {...props} />;
};

const DayScaleCellCustom = (data) => {
}

const BooleanEditor = props => {
  return <AppointmentForm.BooleanEditor {...props} readOnly />;
};

const eventTypeData = [
  { text: 'Sphera', id: 1, color: "red" },
  { text: 'Google', id: 2, color: "green" },
];

class Calendar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentDate: new Date(),
      isShiftPressed: false,
      locale: 'en-GB',
      addedAppointment: {},
      resources: [{
        fieldName: 'eventtype',
        title: 'Type',
        instances: eventTypeData,
      }],
      grouping: [{
        resourceName: 'eventtype',
      }, {
        resourceName: 'project',
      }],

    };
    this.currentDateChange = (currentDate) => { this.setState({ currentDate }); };
    this.commitChanges = this.commitChanges.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

    this.changeAddedAppointment = this.changeAddedAppointment.bind(this);
  }

  componentDidMount() {
    this.props.listProjects();

    this.props.listTrackings();

    //TODO check if google add-on is enabled
    if(checkAddOnEnabledByCName(this.props.self.userAddOns,'google-calendar')){
      this.props.listGoogleEvents();
    }

    this.interval = setInterval(
      () => this.props.listTrackings(),
      600000
    );


    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  }

  componentWillMount() {
    //Im not sure wht I need this
    //teoria: not memory leak
    clearInterval(this.interval);
  }

  onKeyDown(event) {
    if (event.keyCode === SHIFT_KEY) {
      this.setState({ isShiftPressed: true });
    }
  }

  onKeyUp(event) {
    if (event.keyCode === SHIFT_KEY) {
      this.setState({ isShiftPressed: false });
    }
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      const { isShiftPressed } = this.state;
      if (added) {
        console.log("added", added);

        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        if(added.project){
          let appointment = {
            startDate: added.startDate,
            endDate: added.endDate,
            title: added.title,
            project: '/projects/' + added.project,
            description: added.notes
          }

          this.props.createTracking(appointment).then((response) =>{
              this.props.listTrackings();
            }
          )
        }else{
          Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Project is required',
              showConfirmButton: false,
              timer: 3500
            }
          )
        }

      }
      if (changed) {
        console.log("changed", changed);
        if (isShiftPressed) {
          const changedAppointment = data.find(appointment => changed[appointment.id]);
          const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
          data = [
            ...data,
            { ...changedAppointment, id: startingAddedId, ...changed[changedAppointment.id] },
          ];
        } else {
          data = data.map(appointment => (
            changed[appointment.id]
              ? { ...appointment, ...changed[appointment.id] }
              : appointment));
          this.props.trackingsList.list.retrieved['hydra:member'].map(appointment => {
            if(changed[appointment.id] && changed[appointment.id].project ){
              changed[appointment.id].project = '/projects/' + changed[appointment.id].project;
            }
            if(!appointment.endDate && changed[appointment.id]){
              changed[appointment.id].endDate = null;
            }
            return(
            changed[appointment.id]
              ? this.props.updateTracking(appointment, changed[appointment.id]).then(() => {
                this.props.listTrackings();
                this.props.activeTracking();
              }

              )
              : appointment

          )})

          if(this.props.googleTrackingList.retrieved){
            this.props.googleTrackingList.retrieved['hydra:member'].map(appointment => {
              if(changed[appointment.id]){
                let newAppointment = {
                  startDate: appointment.startDate,
                  endDate: appointment.endDate,
                  title: appointment.title,
                  project: '/projects/' + changed[appointment.id].project,
                  description: appointment.notes
                }

                this.props.createTracking(newAppointment).then((response) =>{
                    this.props.listTrackings();
                  }
                )
                return appointment;
              }else{
                return appointment;
              }

            });
          }

        }
      }
      if (deleted !== undefined) {
        data = data.filter(appointment => appointment.id !== deleted);
        let deletedItem = this.props.trackingsList.list.retrieved['hydra:member'].filter(appointment => appointment.id === deleted);

        this.props.deleteTracking(deletedItem[0]);


      }
      return { data };
    });
  }

  changeAddedAppointment(addedAppointment) {
    console.log("change added appointment", addedAppointment);
    this.setState({ addedAppointment });
  }

  render() {

    let { currentDate, data, resources, locale, addedAppointment, grouping}  = this.state;


    let resourcesCustom = [];
    if(this.props.projectList.retrieved){

      let instancesCustom = this.props.projectList.retrieved['hydra:member'].map(project =>{
        return {
          text: project.name,
          id: project.id,
          color: project.color,
        }
      });

      resourcesCustom = [{
        fieldName: 'project',
        title: 'Project',
        instances: instancesCustom
      }]


    }

    resources = [...resources, ...resourcesCustom];

    let appointments = [];
    if(this.props.trackingsList.list.retrieved){
      appointments = this.props.trackingsList.list.retrieved['hydra:member'].map(item => {
        return {
          title: item.title,
          startDate: new Date(item.startDate),
          endDate: item.endDate ? new Date(item.endDate) : new Date(),
          id: item.id,
          project: item.project[0].id,
          notes: item.description,
          active: !item.endDate,
          eventtype: "Sphera"
        }
      })

      data = appointments;
    }
    let appointmentsGoogle = [];
    if(this.props.googleTrackingList.retrieved){
      appointmentsGoogle = this.props.googleTrackingList.retrieved['hydra:member'].map( item => {
        return {
          title: item.title,
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate),
          id: item.id,
          project: item.project,
          notes: "",
          active: false,
          disabled: true,
          readOnly: true,
          allowDragging: false,
          draggable: false,
          eventtype: "Google"
        }
      })
    }

    if(appointmentsGoogle.length > 0){
      data = [...data, ...appointmentsGoogle];
    }

    return (
      <div style={{minHeight: "900px"}}>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle title="Calendar" subtitle="Tracker"
                       className="text-sm-left mb-3"/>
          </Row>
          <Row>
            <Paper>
              <Scheduler
                data={data}
                locale={locale}
                firstDayOfWeek={1}
              >
                <ViewState
                  currentDate={currentDate}
                  onCurrentDateChange={this.currentDateChange}
                  defaultCurrentViewName="work-week"
                />
                <EditingState
                  onCommitChanges={this.commitChanges}
                />
                <EditRecurrenceMenu />
                <DayView
                  startDayHour={7}
                  endDayHour={21}
                  cellDuration={60}
                />
                <WeekView
                  //dayScaleCellComponent={DayScaleCell}
                  /*timeTableCellComponent={TimeTableCell}*/
                  cellDuration={60}
                />

                <WeekView
                  name="work-week"
                  displayName="Work Week"
                  excludedDays={[0, 6]}
                  startDayHour={6}
                  endDayHour={22}
                  cellDuration={60}
                />
                <MonthView />

                <Appointments
                  appointmentComponent={appointmentLayout}
                />
                <AppointmentTooltip
                  showCloseButton
                  showOpenButton
                />
                <AppointmentForm
                  basicLayoutComponent={AppointmentFormBasicLayout}
                  booleanEditorComponent={BooleanEditor}
                />
                <Resources
                  data={resourcesCustom}
                  mainResourceName="project"
                />
                <DragDropProvider
                  allowDrag={allowDrag}
                />
                <CurrentTimeIndicator
                  updateInterval="10000"
                  shadePreviousCells={true}
                  shadePreviousAppointments={true}
                />
                <Toolbar />
                <DateNavigator />
                <TodayButton />
                <ViewSwitcher />
                <ConfirmationDialog />
              </Scheduler>
            </Paper>
          </Row>

        </Container>

      </div>
    );
  }
}

Calendar.propTypes = {};

Calendar.defaultProps = {};

const mapStateToProps = state => {
  const self = state.user.self.info;

  const trackingsList = state.tracking;
  const projectList = state.project.list;

  const googleTrackingList = state.tracking.listGoogleEvents;

  return {self, trackingsList, projectList, googleTrackingList}
};

const mapDispatchToProps = dispatch => ({
  listTrackings: (page) => dispatch(listTrackings(page)),
  listGoogleEvents: () => dispatch(listGoogleEvents()),
  createTracking: (values) => dispatch(create(values)),
  listProjects: (page) => dispatch(listProjects(page)),
  updateTracking: (item, values) => dispatch(update(item, values)),
  deleteTracking: (item) => dispatch(del(item)),
  activeTracking: () => dispatch(activeTracking())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);

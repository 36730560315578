import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {list, reset} from "../../actions/client/list";
import {
  Modal, ModalBody,
  ModalHeader,
  Row,
  Button, Col
} from "shards-react";
import ClientCard from "../../views/Clients/ClientCard";
import Create from "./Create";
import CircularProgress from "@material-ui/core/CircularProgress";



class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetCreated: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      openCreateClient: false
    }
  }

  componentDidMount() {
    //this.props.resetCreated();
    this.props.list();
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  handleOpenCreateClient = () => {
    this.setState({
      openCreateClient: true
    })
  };

  handleCloseCreateClient = () => {
    this.setState({
      openCreateClient: false
    })
  };



  render() {


    return (
      <>
        <Row>
          <Col lg={{ size:8}} className="mb-4">
            <Button
              onClick={this.handleOpenCreateClient}
            >
              Create Client

            </Button>
          </Col>
        </Row>
        <Row>
          {this.props.loading && (
            <Col lg={{size:1, offset:5}}>
              <CircularProgress />
            </Col>

          )}
          {this.props.retrieved &&
          this.props.retrieved['hydra:member'].map((item) => {
            return(
              <ClientCard item={item} key={item.id}/>
            )
          })}

        </Row>
        <Modal
          open={this.state.openCreateClient}
          onClose={this.handleCloseCreateClient}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          toggle={this.handleCloseCreateClient}
        >
          <ModalHeader toggle={this.handleCloseCreateClient}>Create new client</ModalHeader>
          <ModalBody>
            <Create/>
          </ModalBody>
        </Modal>
      </>

    );
  }

}

const mapStateToProps = (state) => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
  } = state.client.list;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = (dispatch) => ({
  list: (page) => dispatch(list(page)),
  //resetCreated: () => dispatch(resetCreated()),
  reset: (eventSource) => dispatch(reset(eventSource)),

});

export default connect(
  mapStateToProps, mapDispatchToProps
)(List);

import {
  fetch,
  normalize,
  extractHubURL,
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'ADDON_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'ADDON_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'ADDON_LIST_SUCCESS', retrieved };
}

export function list(page = '/add_ons') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(loading(false));
        dispatch(success(retrieved));

      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'ADDON_LIST_RESET' });
  };
}

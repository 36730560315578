import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import moment from 'moment';


const TimeCounter = (props) => {

  const [seconds, setSeconds] = useState("-");
  const [minutes, setMinutes] = useState("-");
  const [hours, setHours] = useState("-");

  useEffect(() => {
    if(props.start != null){
      let startTime = moment(props.start);
      let interval = setInterval(() => {

        let currentTime = moment(new Date());

        let time = moment(currentTime.diff(startTime));

        setSeconds(moment(time).format('ss'));
        setMinutes(moment(time).format('mm'));
        //hay que restarle uno a la hora porque si no falla
        //TODO revisar
        setHours(moment(time).format('h') -1);

      },400);
      return () => clearInterval(interval)
    }else{
      setSeconds("00");
      setMinutes("00");
      setHours("00");
    }

  })


  return (
    <>
      <h4 className="font-weight-bold">{hours}:{minutes}:{seconds}</h4>
    </>
  )
}

TimeCounter.propTypes = {};

TimeCounter.defaultProps = {};

export default TimeCounter;

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Button, Col, FormInput, Row} from "shards-react";

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  renderField = (data) => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className="form-group">
        <label htmlFor={`user_${data.input.name}`}>{data.label}</label>
        <FormInput
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`user_${data.input.name}`}
          label={data.input.name}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit}>
          <Row>
            <Col lg={{ size:8, offset:2}} className="mb-4">
              <Field
                component={this.renderField}
                name="name"
                type="text"
                label="Name"
                placeholder="Name of the client."
                required={true}
              />
              <Field
                component={this.renderField}
                name="address"
                type="text"
                label="Address"
                placeholder="Client address."
                required={true}
              />

            </Col>
            <Col lg={{ size:8, offset:2}} className="mb-4">
              <Button block theme="success">
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </>

    );
  }
}

export default reduxForm({
  form: 'client',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Form);

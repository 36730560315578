import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import routes from '../routes';

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {logout} from "../actions/auth/logout";

class DefaultLayout extends React.Component {
  render() {
    let {children, noNavbar, noFooter} = this.props;
    return (
      <Container fluid>
        <Row>
          <MainSidebar user={this.props.self} company={this.props.company}/>
          <Col
            className="main-content p-0"
            lg={{size: 10, offset: 2}}
            md={{size: 9, offset: 3}}
            sm="12"
            tag="main"
          >
            {!noNavbar && <MainNavbar/>}
            {children}
            <Switch>
              {/*eslint-disable-next-line*/}
              {routes.map((route, idx) => {
                if (this.props.token !== null) {
                  for (let role in route.roles) {
                    for (let user_role in this.props.user.roles) {
                      if (route.roles[role] === this.props.user.roles[user_role]) {

                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                              <route.component
                                {...props}
                              />
                            )}/>
                        ) : (null);
                      }
                    }
                  }
                }
              })}
              {this.props.token &&
              <Redirect from="/" to="/calendar"/>
              }}
              {!this.props.token &&
              <Redirect from="/" to="/login"/>
              }}

            </Switch>
            {!noFooter && <MainFooter/>}
          </Col>
        </Row>
      </Container>
    );
  }
}

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

const mapStateToProps = state => {
  const self = state.user.self.info;
  const company = state.company;

  return {self, company}
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);

import React from "react";
import {Container, Row, Col, Alert} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import UserDetails from "../../components/users/UserDetails";
import UserAccountDetails from "../../components/users/UserAccountDetails";
import CompanyDetails from "../../components/company/CompanyDetails";
import CompanyAccountDetails
  from "../../components/company/CompanyAccountDetails";

const CompanyProfile = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Company Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />

      <p className="font-italic visible-page-message">** This page is only visible for managers</p>
    </Row>
    <Row>
      <Col lg="4">
        <CompanyDetails />
      </Col>
      <Col lg="8">
        <CompanyAccountDetails/>
      </Col>
    </Row>
  </Container>
);

export default CompanyProfile;

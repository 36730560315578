import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';

import {Provider} from "react-redux";
import store, {history} from "./store";
import {ConnectedRouter} from "connected-react-router";
import {info} from "./actions/user/self";
import { fetch } from './utils/dataAccess';

import './shards-dashboard/styles/scss/shards-dashboards.scss'
import {success} from "./actions/company/self";

let token = localStorage.getItem('token');
if(token){
  let options = {};
  options.headers = new Headers();
  options.headers.set('Authorization', 'Bearer ' + token);
  fetch('/users/self', options)
    .then((response) => response.json().then((retrieved) => ({ retrieved })))
    .then(
      fetch('/companies/self', options)
        .then((response2) => response2.json().then((retrieved2) => ({ retrieved2 })))
        .then(({ retrieved2 }) => {
            const company = retrieved2;
            store.dispatch(success(company));
          }
        )
    )
    .then(({ retrieved }) => {
      const user = retrieved;
      store.dispatch({ type: 'AUTH_LOGIN_SUCCESS', token });
      store.dispatch(info(user));

      ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
              <App store={store} />
          </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
      );
    })
    .catch((e) => {
      localStorage.clear();
      //store.dispatch(logout());
      ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
              <App store={store} />
          </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
      );
    });
}else{
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

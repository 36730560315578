import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox"
import {Button, Col, FormCheckbox, FormInput, Row} from "shards-react";

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  renderField = (data) => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className="form-group">
        <label
          htmlFor={`team_${data.input.name}`}
          className="form-control-label">
          {data.label}
        </label>
        <FormInput
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`team_${data.input.name}`}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderCheckbox = ({ input, label }) => (
    <div className="form-group pl-2">
      <FormControlLabel
        control={
          <FormCheckbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    </div>
  )



  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit}>
          <Row>
            <Col lg={{ size:8, offset:2}} className="mb-4">
              <Field
                component={this.renderField}
                name="name"
                label="Name"
                type="text"
                placeholder="The name."
                required={true}
              />
              <Field name="billable" component={this.renderCheckbox} label="Billable" />
              <Field
                name="color"
                component={this.renderField}
                label="Color"
                type="color"
              />
              <Field
                name="client"
                component={this.renderField}
                label=""
                type="hidden"
              />
            </Col>
            <Col lg={{ size:8, offset:2}} className="mb-4">
              <Button block theme="success">
                Submit
              </Button>
            </Col>
          </Row>

        </form>
      </>

    );
  }
}

export default reduxForm({
  form: 'project',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Form);

import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
    return { type: 'AUTH_CHANGE_PASSWORD_ERROR', error };
}

export function loading(loading) {
    return { type: 'AUTH_CHANGE_PASSWORD_LOADING', loading };
}

export function success(success) {
    return { type: 'AUTH_CHANGE_PASSWORD_SUCCESS', success };
}

export function change_password(values) {
    return dispatch => {
        dispatch(loading(true));

        return fetch('/change_password', { method: 'POST', body: JSON.stringify(values) })
            .then(response => {
                if (response.ok){
                    dispatch(success(true));
                }
                dispatch(loading(false));
            })
            .catch(e => {
                dispatch(loading(false));
                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(success(false));
    };
}

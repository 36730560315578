import React from "react";
import {
  Container,
  Row,
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import List from "../../components/add-ons/List";

const AddOns = () => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="AddOns" subtitle="Integrations" className="text-sm-left mb-3" />
    </Row>
    <List/>



  </Container>
);

export default AddOns;

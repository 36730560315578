import React from "react";
import {connect} from "react-redux";

import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import UpdateSelf from "./UpdateSelf";

//my components

const CompanyAccountDetails = ({ title, companyId }) => {

  return(
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <UpdateSelf companyId={companyId}/>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );

};

CompanyAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

CompanyAccountDetails.defaultProps = {
  title: "Company Account Details"
};

const mapStateToProps = (state, ownProps) => ({
  companyId: state.user.self.info.company.id
})

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccountDetails);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from './Form';
import { create, reset } from '../../actions/client/create';

class Create extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    //if (this.props.created) return <Redirect to={'/projects'} />;
    if(this.props.created){
      return(
        <h1>Item created successfully</h1>
      )
    }

    return (
      <div>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <Form onSubmit={this.props.create} values={this.props.item} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { created, error, loading } = state.client.create;
  return { created, error, loading };
};

const mapDispatchToProps = (dispatch) => ({
  create: (values) => dispatch(create(values)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);

import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainFooter from "../components/layout/MainFooter";
import MainNavbarCustom from "../components/layout/MainNavbar/MainNavbarCustom";
import {Route, Switch} from "react-router-dom";
import BlankPage from "../views/BlankPage";
import HomePage from "../views/no-auth/HomePage";
import ServicesPage from "../views/no-auth/ServicesPage";
import AboutPage from "../views/no-auth/AboutPage";

const CustomLayout = ({ children, noNavbar, noFooter, menuItems }) => (
  <Container fluid>
    <Row>
      <Col
        className="main-content p-0"
        sm="12"
        tag="main"
      >
        {!noNavbar && <MainNavbarCustom />}
        {children}
        <Switch>
          {/*eslint-disable-next-line*/}
          {menuItems.map((route, idx) => {

            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  <route.component
                    {...props}
                  />
                )} />
            ) : (null);


          })}

        </Switch>
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
  </Container>
);

CustomLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

CustomLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
  contained: false,
  copyright: "Copyright © 2018 DesignRevision",
  menuItems: [
    {
      path: "/blank",
      exact: true,
      layout: CustomLayout,
      component: BlankPage
    },
    {
      path: "/home",
      exact: true,
      layout: CustomLayout,
      component: HomePage
    },
    {
      path: "/services",
      exact: true,
      layout: CustomLayout,
      component: ServicesPage
    },
    {
      path: "/about",
      exact: true,
      layout: CustomLayout,
      component: AboutPage
    },
  ]
};

export default CustomLayout;

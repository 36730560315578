import {fetch, extractHubURL, normalize,} from "../../utils/dataAccess"
import {ENTRYPOINT, MERCURE_HUB_URL} from "../../config/entrypoint";
import {EventSourcePolyfill} from "event-source-polyfill";

export function info(user) {
  return {type: 'USER_UPDATE', user};
}

export function updateFromToken(token) {
  return dispatch => {
    if (token) {
      let options = {};
      options.headers = new Headers();
      options.headers.set('Authorization', "Bearer " + token);
      fetch("/users/self", options).then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
        .then(({ retrieved, hubURL }) => {
          const user = retrieved;
          dispatch(info(user));
            dispatch(mercureAlertsSubscribe(retrieved.id));
        })
        .catch(e => {

        });
     /* let decoded_token = decode(token);
      let user = {
        username: decoded_token.username,
        roles: decoded_token.roles
      };

      dispatch(info(user));*/
    }
  };
}

export function resetAlert() {
    return dispatch => {
        dispatch(mercureAlertMessage(null))

        dispatch({ type: 'USER_ALERT_RESET' });
    };
}
export function userSelf() {
  return dispatch => {
    return fetch("/users/self")
        .then(response =>
            response
            .json()
            .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
        ).then(({ retrieved, hubURL }) => {
          const user = retrieved;

          dispatch(info(user));

        })
        .catch(e => {

        }
        );
  };
}

export function mercureAlertsSubscribe(name){

    const url = new URL(MERCURE_HUB_URL);
    url.searchParams.append('topic', ENTRYPOINT +'/' + name + '/alert');
    url.searchParams.append('topic', ENTRYPOINT + '/alert');

    var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOltdfX0.Oo0yg7y4yMa1vr_bziltxuTCqb8JVHKxp-f_FwwOim0';

    return dispatch => {
        const es = new EventSourcePolyfill(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
        dispatch(mercureAlertOpen(es));

        es.addEventListener('message', event =>{
            //var data = JSON.parse(event.data);
            var urlParams = new URLSearchParams(event.target.url);
            if(urlParams.get('topic') === ENTRYPOINT + '/alert'){

                //this.showNotifications();
            }
            dispatch(mercureAlertMessage(normalize(JSON.parse(event.data))))
            }

        );

    }

}

export function mercureAlertOpen(eventSource) {
    return { type: 'USER_ALERT_MERCURE_OPEN', eventSource };
}

export function mercureAlertMessage(retrieved) {
    return dispatch => {
        dispatch({ type: 'USER_ALERT_RESET' });
        dispatch({ type: 'USER_ALERT_MERCURE_MESSAGE', retrieved });
    };
}


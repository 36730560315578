import React from "react";
import {
  Badge,
  Modal, ModalBody, ModalHeader,
} from "shards-react";
import Update from "../../components/projects/Update";

function ProjectPill(props){

  const [open, setOpen] = React.useState(false);

  let project = props.project;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <Badge
        pill
        style={{backgroundColor: project.color}}
        onClick={handleOpen}
        className="p-3 font-weight-bold"
      >
        {project.name}
      </Badge>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        toggle={handleClose}
      >
        <ModalHeader toggle={handleClose}>Edit Project {project.name}</ModalHeader>
        <ModalBody>
          <Update
            projectId={project.id}
          />
        </ModalBody>
      </Modal>

    </>
  )
}

ProjectPill.propTypes = {};

ProjectPill.defaultProps = {};

export default ProjectPill;

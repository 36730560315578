import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import {connect} from "react-redux";
import {logout} from "../../../../actions/auth/logout";
import Avatar from "@material-ui/core/Avatar";

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {

    return (
      <div>
        {this.props.user ? (
          <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
            <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
              <Avatar
                style={{width: "20px", height: "20px"}}
                src={this.props.user.profileImageFilename}
              >
                {this.props.user.name.charAt(0).toUpperCase()}{this.props.user.lastName ? this.props.user.lastName.charAt(0).toUpperCase() : ""}
              </Avatar>
              {"  "}
              <span className="d-none d-md-inline-block">{this.props.user.name}</span>
            </DropdownToggle>
            <Collapse tag={DropdownMenu} right small open={this.state.visible}>
              <DropdownItem tag={Link} to="/user-profile">
                <i className="material-icons">&#xE7FD;</i> Profile
              </DropdownItem>
              {this.props.user.roles.indexOf('ROLE_MANAGER') > -1 && (
                <DropdownItem tag={Link} to="/company/profile">
                  <i className="material-icons">&#xE8B8;</i> Company Profile
                </DropdownItem>
              )}

              {/*<DropdownItem tag={Link} to="file-manager-list">
                <i className="material-icons">&#xE2C7;</i> Files
              </DropdownItem>
              <DropdownItem tag={Link} to="transaction-history">
                <i className="material-icons">&#xE896;</i> Transactions
              </DropdownItem>
              <DropdownItem tag={Link} to="/">
                <i className="material-icons">&#xE896;</i> Dashboard
              </DropdownItem>*/}
              <DropdownItem divider />

              <DropdownItem className="text-danger">
                <i className="material-icons text-danger">&#xE879;</i> <span onClick={this.props.logout}>Logout</span>
              </DropdownItem>


            </Collapse>
          </NavItem>
        ):(
          <div>
            <NavItem key="login">
              <NavLink tag={Link} to={"/login"}>
                Login
              </NavLink>
            </NavItem>
          </div>
        )}

      </div>


    );
  }
}

const mapStateToProps = state => {
  const { token, error, loading } = state.auth.login;
  const user = state.user.self.info;
  return { token, error, loading, user };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);

import React from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card
} from "shards-react";
import ReportComponent from "../../components/reports/ReportComponent";
import PageTitle from "../../components/common/PageTitle";

const Reports = ({ smallStats }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Reports" subtitle="Filters" className="text-sm-left mb-3" />
    </Row>

    <Row noGutters className="page-header py-4">
      <Col lg="12" md="12" sm="12" className="mb-4">
        <Card className="">
          <CardBody className="pt-0" style={{minHeight: "600px"}}>
            <Row className="">
              <Col sm="12" className="d-flex mb-2 mb-sm-0">
                <ReportComponent/>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>

  </Container>
);

export default Reports;

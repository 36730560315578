import { combineReducers } from 'redux';

export function error(state = null, action) {
    switch (action.type) {
        case 'AUTH_CHANGE_PASSWORD_ERROR':
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'AUTH_CHANGE_PASSWORD_LOADING':
            return action.loading;

        default:
            return state;
    }
}

export function success(state = null, action) {
    switch (action.type) {
        case 'AUTH_CHANGE_PASSWORD_SUCCESS':
            return action.success;

        default:
            return state;
    }
}

export default combineReducers({ error, loading, success});
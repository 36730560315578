import React from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
} from "shards-react";
import Slider from "../../components/common/Slider";
import AnonymousForm from "../../components/anonymous-form/AnonymousForm";


const HomePage = ({ smallStats }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row>
      <Slider/>
    </Row>

    <Row noGutters className="page-header py-4">
      <Col lg="6" md="6" sm="6" className="mb-4">
        <Card className="">
          <CardBody className="pt-0" style={{minHeight: "600px"}}>
            <AnonymousForm/>
          </CardBody>
        </Card>
      </Col>
    </Row>

  </Container>
);

export default HomePage;

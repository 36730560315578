/**
 * Created by pere on 30/09/20.
 */
import { combineReducers } from 'redux';

import list from './list';
import listSelect from './listSelect';
import del from './delete';
import create from './create';
import update from './update';

export default combineReducers({ list, del, create, update, listSelect});

import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import {DefaultLayout} from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Dashboard from "./views/Dashboard/Dashboard";
import Requests from "./views/Requests/Requests";
import Users from "./views/Users/Users";
import Calendar from "./views/Calendar/Calendar";
import Projects from "./views/Projects/Projects";
import Reports from "./views/Reports/Reports";
import UserProfile from "./views/Users/UserProfile";
import CompanyProfile from "./views/Company/CompanyProfile";
import AddOns from "./views/AddOns/AddOns";
import GoogleAuthentication
  from "./components/add-ons/Google/GoogleAuthentication";
import GoogleCalendars from "./components/add-ons/Google/GoogleCalendars";

export default [
  {
    path: "/",
    title: "Calendar",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/calendar" />,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    layout: DefaultLayout,
    component: Dashboard,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/requests",
    title: "Requests",
    layout: DefaultLayout,
    component: Requests,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/calendar",
    title: "Calendar",
    layout: DefaultLayout,
    component: Calendar,
    roles: ['ROLE_USER'],
    sidebarVisible: true,
    htmlBefore: '<i class="material-icons">calendar_today</i>',
    htmlAfter: "",
  },
  {
    path: "/projects",
    title: "Projects",
    layout: DefaultLayout,
    component: Projects,
    roles: ['ROLE_USER'],
    sidebarVisible: true,
    htmlBefore: '<i class="material-icons">work</i>',
    htmlAfter: "",
  },
  {
    path: "/users",
    title: "Users",
    layout: DefaultLayout,
    component: Users,
    roles: ['ROLE_MANAGER'],
    sidebarVisible: true,
    htmlBefore: '<i class="material-icons">supervisor_account</i>',
    htmlAfter: "",
  },
  {
    path: "/add-ons",
    title: "AddOns",
    layout: DefaultLayout,
    component: AddOns,
    roles: ['ROLE_USER'],
    sidebarVisible: true,
    htmlBefore: '<i class="material-icons">view_module</i>',
    htmlAfter: "<i>New</i>",
  },
  {
    path: "/reports",
    title: "Reports",
    layout: DefaultLayout,
    component: Reports,
    roles: ['ROLE_MANAGER'],
    sidebarVisible: true,
    htmlBefore: '<i class="material-icons">anchor</i>',
    htmlAfter: "<i>New</i>",
  },
  {
    path: "/user-profile",
    title: "UserProfile",
    layout: DefaultLayout,
    component: UserProfile,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/company/profile",
    title: "CompanyProfile",
    layout: DefaultLayout,
    component: CompanyProfile,
    roles: ['ROLE_MANAGER'],
    sidebarVisible: false
  },
  {
    path: "/google_authentication",
    title: "Google Authentication",
    layout: DefaultLayout,
    component: GoogleAuthentication,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/google_calendars/configuration",
    title: "Google Calendars Configuration",
    layout: DefaultLayout,
    component: GoogleCalendars,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts,
    roles: ['ROLE_USER'],
    sidebarVisible: false
  }
];

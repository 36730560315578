import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authentication} from "../../../actions/add-on/googleCalendar/authorize";

import queryString from 'query-string'

class GoogleAuthentication extends Component {

  constructor(props) {
    super(props);
    this.state = {}

  }


  componentDidMount() {
    console.log("url params")
    console.log(this.props)
    console.log(this.props.location.query)
    console.log(this.props.location.search)
    const values = queryString.parse(this.props.location.search)
    console.log(values)
    if(values.code){
      console.log("habemus code");
      console.log(values.code)
      this.props.authorize(values.code).then(()=> this.props.history.push("/google_calendars/configuration"));
      //this.props.authorize(values.code).then(()=> this.props.history.push("/user-profile"));
    }

  }

  componentWillUnmount() {
  }

  render() {

    return (
      <div>
        <h1>Google Authentication</h1>
        {this.props.self.authUrl && this.props.self.authUrl !== "authorized" && (
          <div>
            <p>we have url auth</p>
            <p>{this.props.self.authUrl}</p>
            <a href={this.props.self.authUrl}>Press here</a>
          </div>
        )}
        {this.props.self.authUrl && this.props.self.authUrl === "authorized" && (
          <div>
            <p>Your account is authorized yet</p>
          </div>
        )}

      </div>


    );
  }

}

const mapStateToProps = state => {
  const self = state.user.self.info;
  const {
    authenticationLoading,
    authenticationError,
    authenticated
  } = state.addons.authorize;
  return {self, authenticationLoading, authenticationError, authenticated}
};

const mapDispatchToProps = dispatch => ({
  authorize: code => dispatch(authentication(code))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleAuthentication);


import React from "react";
import {
  Container, Row,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import List from "../../components/clients/List";

const Projects = () => (
  <div style={{minHeight: "900px"}}>
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Projects" subtitle="Clients" className="text-sm-left mb-3" />
      </Row>

      <List/>

    </Container>

  </div>
);

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {extendMoment} from "moment-range";
import Moment from "moment";
import TablePaginationActions from "../Filters/TablePaginationActions";

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({

  table: {
    minWidth: 650,
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  head: {
    backgroundColor: "#007bff",
    color: theme.palette.common.white,
  },
}));

export default function UserTable(props) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.report.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>Description</TableCell>
              <TableCell className={classes.head} align="right">Date</TableCell>
              <TableCell className={classes.head} align="right">Project</TableCell>
              <TableCell className={classes.head} align="right">Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.report.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : props.report).map((row) => {
                  let diff = moment(row.endDate).diff(moment(row.startDate), "minutes");
                  if(diff >= 60){
                    diff = Math.round(diff/60);
                    diff = diff + " hours";
                  }else{
                    diff = diff + " minutes";
                  }
                  return(
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>
                      <TableCell
                        align="right">{moment(row.startDate).format('YY/MM/DD hh:mm:ss')}</TableCell>
                      <TableCell align="right">{row.project.name}</TableCell>
                      <TableCell
                        align="right">{diff}</TableCell>
                    </TableRow>
                    )

            })}

          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={3}>Total</TableCell>
              <TableCell align="right">{props.total.totalTime < 60 ? props.total.totalTime + " min" : Math.round(props.total.totalTime/60) + " hours"}</TableCell>
            </TableRow>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={props.report.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

import { fetch } from '../../utils/dataAccess';
import Swal from "sweetalert2";

export function error(error) {
  return { type: 'TRACKING_DELETE_ERROR', error };
}

export function loading(loading) {
  return { type: 'TRACKING_DELETE_LOADING', loading };
}

export function success(deleted) {
  return { type: 'TRACKING_DELETE_SUCCESS', deleted };
}

export function rechargeList(deleted){
  return { type: 'TRACKING_SELF_LIST_DELETE_ITEM', deleted};
}

export function del(item) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(item['@id'], { method: 'DELETE' })
      .then(() => {
        dispatch(loading(false));
        dispatch(success(item));
        dispatch(rechargeList(item));
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'Item deleted',
            showConfirmButton: false,
            timer: 3500
          }
        )
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.message,
            showConfirmButton: false,
            timer: 3500
          }
        )
      });
  };
}

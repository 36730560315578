import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {Button, Col, FormInput, FormSelect, Row} from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";

class FormSelf extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  renderField = (data) => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className="form-group">
        <label htmlFor={`user_${data.input.name}`}>{data.label}</label>
        <FormInput
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`user_${data.input.name}`}
          label={data.input.name}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderSelectField = ({
   input,
   label,
   meta: { touched, error },
   children,
   ...custom
  }) => (
    <div className="form-group">
      <label htmlFor={`user_${input.name}`}>{label}</label>

      <FormSelect
          native
          {...input}
          {...custom}
          inputProps={{
            name: 'roles',
            id: 'roles-native-simple'
          }}
        >
          {children}
        </FormSelect>
    </div>
  )

  render() {

    return (
      <>
        <form onSubmit={this.props.handleSubmit}>
          <Row form>
            <Col md="6" className="form-group">
              <Field
                component={this.renderField}
                name="email"
                label="Email"
                type="email"
                placeholder="The e-mail to communicate with the user."
                required={true}
              />
            </Col>
            <Col md="6" className="form-group">
              <Field
                component={this.renderField}
                name="username"
                label="Username"
                type="text"
                placeholder="username."
                required={true}
              />

            </Col>
          </Row>
          <Row form>
            <Col md="6" className="form-group">
              <Field
                component={this.renderField}
                name="name"
                label="Name"
                type="text"
                placeholder="Name of the user."
                required={true}
              />

            </Col>
            <Col md="6" className="form-group">
              <Field
                component={this.renderField}
                name="lastName"
                label="Last Name"
                type="text"
                placeholder="Last Name of the user."
                required={true}
              />
            </Col>
          </Row>
          <Row form>
            <Col md="6" className="form-group">
              <Field
                component={this.renderField}
                name="phone"
                label="Phone"
                type="text"
                placeholder="Phone of the user."
                required={false}
              />
            </Col>

          </Row>


          <Col lg={{ size:8, offset:2}} className="mb-4">
            <Button block theme="success">
              Submit
            </Button>
          </Col>

        </form>
      </>
    );
  }
}

export default reduxForm({
  form: 'user',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormSelf);

import React, { Component } from 'react';
import Form from './Form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { recover_password, reset } from '../../../actions/auth/recoverpassword';
import { Link, Redirect } from 'react-router-dom';
import logo from "../../../images/brand/logo_black.png";

class RecoverPassword extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.object,
    recover_password: PropTypes.func,
  };

  componentDidMount() {}
  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    return (
      <div className="auth__main">
        <div className="auth__box-container">
          <h3 className="auth__title">Recover Password</h3>
          <div>
            {!this.props.loading && !this.props.success && (
              <>
                <Form
                  onSubmit={this.props.recover_password}
                  values={this.props.item}/>
                <Link className="link" to={`/login`}>
                  Back to Login
                </Link>
              </>


            )}
            {this.props.loading && (
              <h2 className="card-header-action text-center mt-3 mb-0" ><i className="fa fa-spin fa-circle-o-notch"></i></h2>
            )}
            {this.props.error && (
              <div style={{marginTop: "20px"}} className="alert alert-danger" role="alert">
                <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                {this.props.error}
              </div>
            )}
            {this.props.success && (
              <div>
                <div className="alert alert-info" role="alert">
                  <span className="" aria-hidden="true" />{' '}
                  <p>Please check your email.</p>
                </div>
                <Link className="link" to={`/login`}>
                  Back to login
                </Link>
              </div>
            )}

          </div>

          <div className="">
            <div>
              <img style={{width:"100%"}} src={logo} alt="Sphera"/>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { success, error, loading } = state.auth.recoverpassword;
  return { success, error, loading };
};

const mapDispatchToProps = (dispatch) => ({
  recover_password: (values) => dispatch(recover_password(values)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);

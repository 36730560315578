import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe,
} from '../../../utils/dataAccess';


export function authenticationError(updateError) {
  return { type: 'USER_GOOGLE_AUTHENTICATION_ERROR', updateError };
}

export function authenticationLoading(updateLoading) {
  return { type: 'USER_GOOGLE_AUTHENTICATION_LOADING', updateLoading };
}

export function authenticationSuccess(updated) {
  return { type: 'USER_GOOGLE_AUTHENTICATION_SUCCESS', updated };
}

export function authentication(values) {

  const customValues = {
    code: values
  }
  return (dispatch) => {
    dispatch(authenticationError(null));
    dispatch(authenticationLoading(true));

    return fetch('users/google_authentication', {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify(customValues),
    })
      .then((response) =>
        response
          .json()
          .then((retrieved) => ({ retrieved }))
      )
      .then(({ retrieved }) => {
        retrieved = normalize(retrieved);

        dispatch(authenticationLoading(false));
        dispatch(authenticationSuccess(retrieved));

      })
      .catch((e) => {
        dispatch(authenticationLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(authenticationError(e.errors._error));
          throw e;
        }

        dispatch(authenticationError(e.message));
      });
  };
}


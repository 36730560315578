import React, { Component } from 'react';
import Form from './Form'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register, reset } from '../../actions/auth/register';
import logo from '../../images/brand/logo_black.png';
import {Link} from "react-router-dom";
import {PhotographerInfo} from "../../views/StaticWidgets/PhotographerInfo";

class Register extends Component {

  static propTypes = {
      error: PropTypes.string,
      loading: PropTypes.bool.isRequired,
      token: PropTypes.string,
      login: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired
  };

  componentDidMount(){

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentWillUnmount() {
        this.props.reset();
    }

  render() {

    if(this.props.flag){
      return (
        <div className="auth__main">
          <div className="auth__box-container">
            <h3 className="auth__title">Register</h3>
            <div>

              <p>Welcome to Sphera, You have successfully created your account, please check your email so we can make sure you're a human</p>

            </div>
            <Link className="pull-right" to={`/login`}>
              Back to login
            </Link>
            <div className="">
              <div>
                <img style={{width:"100%"}} src={logo} alt="Sphera"/>
              </div>

            </div>

          </div>


        </div>
      )
    }

    return (

      <div className="auth__main">
        <div className="auth__box-container">
          <h3 className="auth__title">Register</h3>
          <div>
            <Form onSubmit={this.props.register}>
            </Form>
            {this.props.loading && (
              <h2 className="card-header-action text-center mt-3 mb-0" ><i className="fa fa-spin fa-circle-o-notch"></i></h2>
            )}
            {this.props.error && (
              <div style={{marginTop: "20px"}} className="alert alert-danger" role="alert">
                <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                {this.props.error}
              </div>
            )}

          </div>
          <Link className="pull-right" to={`/login`}>
            Back to login
          </Link>
          <div className="">
            <div>
              <img style={{width:"100%"}} src={logo} alt="Sphera"/>
            </div>

          </div>

        </div>

        <PhotographerInfo/>


      </div>
    );
  }
}


const mapStateToProps = state => {
    const { flag, error, loading } = state.auth.register;
    return { flag, error, loading };
};

const mapDispatchToProps = dispatch => ({
    register: values => dispatch(register(values)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class NotAuthentication extends Component {
    componentWillMount() {
      console.log('que hay aqui');
      if (this.props.token && this.props.user) {
        this.props.history.push('/calendar');
      }
    }
    componentWillUpdate(nextProps) {
      if (nextProps.token && nextProps.user) {
        this.props.history.push('/calendar');
      }
    }
    PropTypes = {
      router: PropTypes.object,
    };
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return {
      token: state.auth.login.token,
      user: state.user.self.info,
    };
  }
  return connect(mapStateToProps)(NotAuthentication);
}

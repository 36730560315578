import React, {useState} from "react";
import {connect} from "react-redux";

import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress, ModalHeader, ModalBody, Modal
} from "shards-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {updateImage} from "../../actions/user/updateSelf";
import Avatar from "@material-ui/core/Avatar";

const UserDetails = ({ userDetails, userInfo, userUpdatedInfo, ...props }) => {

  const [openImageModal, setOpenImageModal] = useState(false);
  const [image, setImage] = useState(userInfo.profileImageFilename);

  function handleOpenImageModal() {

    setOpenImageModal(true);

  }

  function handleCloseImageModal() {

    setOpenImageModal(false);

  }

  const handleChangeImage = (evt) => {
    console.log("Uploading");
    var reader = new FileReader();
    var file = evt.target.files[0];

    console.log(file);

    reader.onload = function(upload) {
      console.log(upload.target.result)
      setImage(upload.target.result)
    };
    reader.readAsDataURL(file);
    console.log(image);
    console.log("Uploaded");
  }

  const sendImage = () => {
    console.log('sending image');
    let values = {};
    values.imageFile = image;
    props.update(values).then(()=>{
      setOpenImageModal(false);
    });

  }


  return(
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">

          <Avatar
            style={{margin: "auto", width: "80px", height: "80px", cursor: "pointer"}}
            src={userInfo.profileImageFilename}
            onClick={handleOpenImageModal}
          >
            {userInfo.name.charAt(0).toUpperCase()}{userInfo.lastName ? userInfo.lastName.charAt(0).toUpperCase() : ""}
          </Avatar>

          <Modal
            open={openImageModal}
            onClose={handleCloseImageModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            toggle={handleCloseImageModal}
          >
            <ModalHeader toggle={handleCloseImageModal}>Change Image</ModalHeader>
            <ModalBody>
              {userUpdatedInfo && userUpdatedInfo.updateLoading && (
                <CircularProgress />
              )}
              {!userUpdatedInfo.updateLoading && (
                <>
                  <div className="mb-3 mx-auto">
                    <Avatar
                      style={{margin: "auto", width: "80px", height: "80px", cursor: "pointer"}}
                      src={image}
                    >
                      {userInfo.name.charAt(0).toUpperCase()}{userInfo.lastName ? userInfo.lastName.charAt(0).toUpperCase() : ""}
                    </Avatar>

                  </div>
                  <div className="custom-file mb-3">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="profileImageFilename"
                      onChange={handleChangeImage}
                    />
                    <label className="custom-file-label" htmlFor="customFile2">
                      Choose file...
                    </label>
                  </div>
                  <Button onClick={sendImage} block theme="success">
                    Send
                  </Button>
                </>
              )}

            </ModalBody>
          </Modal>

        </div>
        <h4 className="mb-0">{userInfo.name} {userInfo.lastName}</h4>
        <span className="text-muted d-block mb-2">{userDetails.jobTitle}</span>
        {/*<Button pill outline size="sm" className="mb-2">
          <i className="material-icons mr-1">person_add</i> Follow
        </Button>*/}
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">
              {userInfo.company.name}
            </strong>

          </div>
        </ListGroupItem>
        {/**<ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">
              {userDetails.performanceReportTitle}
            </strong>
            <Progress
              className="progress-sm"
              value={userDetails.performanceReportValue}
            >
            <span className="progress-value">
              {userDetails.performanceReportValue}%
            </span>
            </Progress>
          </div>
        </ListGroupItem>
        <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">
            {userDetails.metaTitle}
          </strong>
          <span>{userDetails.metaValue}</span>
        </ListGroupItem>**/}
      </ListGroup>
    </Card>
    )

};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Sierra Brook",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};


const mapStateToProps = (state, ownProps) => ({
  userInfo: state.user.self.info,
  userUpdatedInfo: state.user.self.update,

})

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    update: (item, values) => dispatch(updateImage(item, values)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

import React from "react";
import {
  Container, Row,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";

const Requests = () => (
  <div style={{minHeight: "900px"}}>
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Solicitudes" subtitle="Solicitudes" className="text-sm-left mb-3" />
      </Row>
      <Row>
        Aqui lo que queramos
      </Row>

    </Container>

  </div>
);

Requests.propTypes = {};

Requests.defaultProps = {};

export default Requests;

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {list} from "../../../actions/add-on/googleCalendar/list";
import {update} from "../../../actions/add-on/googleCalendar/update";
import {Alert, Card, CardBody, Col, Container, Row} from "shards-react";
import PageTitle from "../../common/PageTitle";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";

class GoogleCalendars extends Component {

  constructor(props) {
    super(props);
    this.state = {
      opennedSnackbar: !!this.props.calendarListUpdate.updated,
      snackbarMessage: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);

  }

  handleInputChange(event){
    console.log("checked")
    console.log(event)
    const target = event.target;
    console.log(target);
    console.log(target.name);
    console.log(target.id);
    console.log(target.value);
    console.log(target.checked)
    //todo update calendar
    const values = {
      sync: target.checked
    }
    let message = <p>Calendar <b>{target.name}</b> enabled successfully</p>;
    if(!target.checked){
      message = <p>Calendar <b>{target.name}</b> removed successfully</p>;
    }
    this.props.update(target.id, values).then(() => {
      this.setState({
        opennedSnackbar: true,
        snackbarMessage: message
      })
    });

  }

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      opennedSnackbar: false,
      snackbarMessage: ""
    })

  }

  componentDidMount() {
    //request to get calendars
    this.props.list();
  }

  componentWillUnmount() {
  }

  render() {

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="Google Calendars" subtitle="Configuration" className="text-sm-left mb-3" />
        </Row>

        <Row noGutters className="page-header py-4">
          <Col lg="8" className="mb-4 p-2">
            <Card className="">
              <CardBody className="pt-0" style={{minHeight: "600px"}}>
                <p>Aqui están los calendarios que hemos enconrtado en tu cuenta</p>
                <Row className="">



                        {this.props.retrieved &&
                        this.props.retrieved['hydra:member'].map((item) => {

                          return (
                            <Col lg="3" className="mb-4 p-2">
                              <div className="m-1 shadow rounded p-2">
                                <Grid container justify="flex-start" className="overflow-hidden">
                                  {item.name}:
                                </Grid>

                                <Grid container justify="flex-end">
                                  <Switch
                                    checked={item.sync ? "true" : ""}
                                    onChange={this.handleInputChange}
                                    color="primary"
                                    name={item.name}
                                    id={item.id}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                </Grid>
                              </div>


                            </Col>
                          );
                        })}

                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" className="p-2">
            <Card className="">
              <CardBody className="pt-0" style={{minHeight: "600px"}}>
                <p>Algo de info</p>
              </CardBody>
            </Card>

          </Col>
        </Row>
        <Snackbar open={this.state.opennedSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
          <Alert onClose={this.handleCloseSnackbar} severity="success">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>

      </Container>



    );
  }

}

const mapStateToProps = state => {
  const self = state.user.self.info;
  const {
    retrieved
  } = state.addons.calendarlist;
  const calendarListUpdate = state.addons.calendarupdate
  return {self, retrieved, calendarListUpdate}
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  update: (id, values) => dispatch(update(id,values))

});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleCalendars);


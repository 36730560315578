import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'USER_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'USER_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'USER_CREATE_SUCCESS', created };
}

export function rechargeList(created){
  return { type: 'USER_LIST_UPDATE', created};
}

export function create(values) {
  if(values.roles){
    values.roles=[values.roles];
  }else{
    values.roles=['ROLE_WORKER'];
  }
  //sobre escribimos el username porque no lo necesitamos de momento
  values.username = values.email;

  return dispatch => {
    dispatch(loading(true));
    return fetch('/manage/users', { method: 'POST', body: JSON.stringify(values) })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        dispatch(success(retrieved));
        dispatch(rechargeList(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
  };
}
